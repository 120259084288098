<template lang="pug">
  .pagination
    el-pagination.mt-3.mb-3.pages-custom-pagination(
      background
      layout="sizes, prev, pager, next"
      :current-page.sync="currentPage"
      :total="totalCount"
      :page-sizes="[10, 50, 100]"
      :page-size="pageSize"
      v-if="totalCount >= 1"
      @current-change="handleGetData"
      @size-change="handleChangePageSize"
    )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";

export default {
  props: {
    view: {
      type: String,
      required: true,
    },

    activeName: {
      type: String,
      required: false,
      default: null,
    },

    search: {
      type: String,
      required: false,
    },

    dateRange: {
      type: Array,
      required: false,
    },

    dateTimeRange: {
      type: Array,
      required: false,
    },

    siteId: {
      type: String,
      required: false,
    },

    fieldId: {
      type: String,
      required: false,
    },


    transmitterId: {
      type: String,
      required: false,
    },

    // ticketTypeId: {
    //   type: String,
    //   required: false,
    // },

    statusType: {
      type: String,
      required: false,
    },

    categoryType:{
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapState("passwordValidationDelete", ["loading", "allowDeletion"]),
    ...mapState("user", ["shouldFetchUsers"]),
    ...mapState("site", ["shouldFetchSites", "site"]),
    ...mapState("field", ["shouldFetchFields", "field"]),
    ...mapState("transmitter", ["shouldFetchTransmitters"]),
    ...mapState("soilSensor", ["shouldFetchSoilSensors"]),

  },

  watch: {
    activeName() {
      this.currentPage = 1;
      this.handleGetData();
    },

    search() {
      this.searchDebounce();
    },

    siteId() {
      this.handleGetData();
    },

    fieldId() {
      this.handleGetData();
    },

    // ticketTypeId() {
    //   this.handleGetData();
    // },

    statusType() {
      this.handleGetData()
    },

    categoryType() {
      this.handleGetData()
    },

    handleGetDataLoading() {
      this.$emit("handleTableLoading", this.handleGetDataLoading);
    },

    loading() {
      if (this.loading === false && this.allowDeletion === true) {
        this.handleGetData();
      }
    },

    dateRange() {
      this.handleGetData();
    },

    dateTimeRange() {
      this.handleGetData();
    },

    // TODO: refactor these in the next tech debt
    // shouldFetchBikes() {
    //   if (this.shouldFetchBikes) {
    //     this.handleGetData();
    //     this.setShouldFetchBikes(false);
    //   }
    // },
    shouldFetchUsers() {
      if (this.shouldFetchUsers) {
        this.handleGetData();
        this.setShouldFetchUsers(false);
      }
    },

    shouldFetchSites() {
      if (this.shouldFetchSites) {
        this.handleGetData();
        this.setShouldFetchSites(false);
      }
    },

    shouldFetchFields() {
      if (this.shouldFetchFields) {
        this.handleGetData();
        this.setShouldFetchFields(false);
      }
    },

    shouldFetchTransmitters() {
      if (this.shouldFetchTransmitters) {
        this.handleGetData();
        this.setShouldFetchTransmitters(false);
      }
    },

    shouldFetchSoilSensors(){
      if (this.shouldFetchSoilSensors) {
        this.handleGetData();
        this.setShouldFetchSoilSensors(false);
      }
    },

    shouldFetchTokens() {
      if (this.shouldFetchTokens) {
        this.handleGetData();
        this.setShouldFetchTokens(false);
      }
    },

    view(){
      this.handleGetData();
    }
  },

  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      handleGetDataLoading: false,
      filteredBikes: [],
      bikes: [],
    };
  },

  mounted() {
    this.pageSize = Number(localStorage.getItem('pageSize')) || 10
    this.handleGetData();
  },

  methods: {
    ...mapMutations("user", ["setShouldFetchUsers"]),
    ...mapMutations("site", ["setShouldFetchSites"]),
    ...mapMutations("field", ["setShouldFetchFields"]),
    ...mapMutations("transmitter", ["setShouldFetchTransmitters"]),
    ...mapMutations("soilSensor", ["setShouldFetchSoilSensors"]),

    ...mapActions("user", ["getUsers"]),
    ...mapActions("site", ["getSites"]),
    ...mapActions("field", ["getFields"]),
    ...mapActions("transmitter", ["getTransmitters"]),
    ...mapActions("soilSensor", ["getSoilSensors"]),

    computedParams() {
      let computedParams = `?page=${this.currentPage}`;

      // if a tab is selected
      if (this.activeName && this.activeName !== "") {
        if (this.view === "users") {
          computedParams += `&by_role_name=${this.activeName}`;
        }
      }

      if (this.search) {
        computedParams += `&by_keyword=${this.search}`;
      }

      if (this.siteId) {
        computedParams += `&by_site_id=${this.siteId}`;
      }

      if(this.fieldId) {
        computedParams += `&by_field_id=${this.fieldId}`;
      }

      if (this.transmitterId) {
        computedParams += `&by_sensor_transmitter_id=${this.transmitterId}`;
      }

      // if (this.ticketTypeId && this.ticketTypeId !== 'All' && this.ticketTypeId !== 'all') {
      //   computedParams += `&by_ticket_type=${this.ticketTypeId}`;
      // }

      // by status type - tickets
      if (this.statusType && this.statusType !== 'All' && this.statusType !== 'all') {
        computedParams += `&by_status_type=${this.statusType}`
      }

      // add page size
      computedParams += `&page_size=${this.pageSize}`

      if (this.dateTimeRange && this.dateTimeRange.length > 0) {
        let dateTimeRangeComputed = {
          start_time: this.dateTimeRange[0],
          end_time: this.dateTimeRange[1],
        };

        computedParams += `&by_datetime_range[start_time]=${dateTimeRangeComputed.start_time}&by_datetime_range[end_time]=${dateTimeRangeComputed.end_time}`;
      }

      if (this.dateRange && this.dateRange.length > 0) {
        let dateRangeComputed = {
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
        };

        computedParams += `&by_date_range[start_date]=${dateRangeComputed.start_date}&by_date_range[end_date]=${dateRangeComputed.end_date}`;
      }

      return { params: computedParams };
    },

    async handleGetData() {
      this.handleGetDataLoading = true;
      let computedParams = this.computedParams();

      const response = await this.filteredGetActionByView(computedParams);
      this.emitDataToView(response);

      this.handleGetDataLoading = false;
    },

    async filteredGetActionByView(computedParams) {
      switch (this.view) {
        case "users":
          return await this.getUsers(computedParams.params);
        case "sites":
          return await this.getSites(computedParams.params);
        case "fields_map":
          return await this.getFields(computedParams.params);
        case "fields_list":
          return await this.getFields(computedParams.params);
        case "tokens":
          return await this.getTokens(computedParams.params);
        case "transmitters":
          return await this.getTransmitters(computedParams.params);
        case "soil_sensors":
          return await this.getSoilSensors(computedParams.params);
        case "client_soil_sensors":
          return await this.getSoilSensors(computedParams.params);
      }
    },

    emitDataToView(response) {
      let data = response.data;
      this.totalCount = Number(response.headers["total-count"]);

      this.$emit("viewData", data);
    },

    searchDebounce: _.debounce(function () {
      this.currentPage = 1;
      this.handleGetData();
    }, 1000),

    async handleChangePageSize(newPageSize) {
      localStorage.setItem('pageSize', newPageSize)

      this.pageSize = newPageSize;
      await this.handleGetData()
    }
  },
};
</script>
