export const SitesViewValidations = {
  data() {
    return {
      addSiteRules: {
        name: [
          {
            required: true,
            message: this.$t("sites.validations.enter_name"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("sites.validations.enter_address"),
            trigger: "blur",
          },
        ]
      },
    };
  },
};
