export const settingsViewValidations = {
  data() {
    return {
      profileDataFormRules: {
        first_name: [
          {
            required: true,
            message: this.$t("settings.validations.enter_first_name"),
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: this.$t("settings.validations.enter_last_name"),
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            validator: this.phoneNumberValidator,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("settings.validations.enter_email"),
            trigger: "blur",
          },
        ],
      },

      passwordFormRules: {
        password: [
          {
            required: true,
            validator: this.validateConfirmationPassword,
            trigger: "blur",
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: this.validateConfirmationPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
};
