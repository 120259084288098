<template lang="pug">
  .bikeing-station-map-wrapper
    GmapMap.custom-map(
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="width: 400px; height: 300px"
    )
    .add-user
      el-form(
        :model="form"
        ref="AddFieldForm"
        :rules="addFieldFormRules"
        @submit.native.prevent
      )
        el-row(:gutter="24")
          el-col(:span="12")
            el-form-item(
              prop="name"
              :label="$t('fields.actions.label.name')"
            )
              el-input(
                v-model="form.name"
                name="name"
              )
          el-col(:span="12")
            el-form-item(
              prop="site_id"
              :label="$t('fields.actions.label.site')"
            )
              el-select.w-100(
                v-model='form.site_id'
                filterable
                allow-create
                :placeholder="$t('fields.actions.label.site')"
              )
                el-option(
                  v-for='site in sites'
                  :key='site.id'
                  :label='site.name'
                  :value='site.id'
                )
      el-row.text-center
        el-button.dark-green-btn.mt-5.pop-up-btn(
          @click="handleAddNewField('AddFieldForm')"
          :loading="loading"
        ) {{ $t('common.add') }}

</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { bikesMaintenanceStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";
import i18n from "@i18n";
import { customValidations } from "@utils/customValidators";
import { fieldsViewValidations } from "@utils/formValidations/fieldsViewValidations";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddField",
  mixins: [fieldsViewValidations, customValidations],

  props: {
    bikes: {
      type: Array,
      default: () => [],
    },
    locationType: {
      type: String,
      default: () => "",
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {

      cnt: 0,
      marker: [],
      markers: [],
      bikesInfo: [],
      polyline: null,
      infowindow: [],
      markerCluster: null,
      getPolylineForBike: 0,
      zoom: 10,
      center: {
        lat: 0,
        lng: 0,
      },
      form: {
        name: null,
        site_id: null
      },
      sites: [],
      loading: false,
      polygoneCoords: [],

      setObjectValues,
      bikesMaintenanceStatuses,
      inBetween,

    };
  },

  watch: {
    google() {
      // this.drawMarkers();
    },

    cnt(){
      if (this.cnt == 6)
      {
        this.$refs.mapRef.$mapPromise.then((map) => {
          google.maps.event.clearListeners(map, 'click')
        })
      }  
    },

  },

  computed: {
    google: gmapApi,

    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.getBrowserLocation();
    await this.handleGetSites();
  },

  async mounted() {
    this.initMap();
    // await this.drawMarkers();
    this.aaa();
  },

  methods: {
    ...mapActions("site", ["getSites"]),
    ...mapActions('field', ['addField']),
    ...mapMutations("dialog", ["hideAddFieldDialog"]),
    ...mapMutations("field", [
      "setField",
      "setShouldFetchFields",
    ]),
    // ...mapActions('tracker', ['getTrackerTrail']),

    async handleGetSites() {
      const sites = await this.getSites("?page_size=1000");
      this.sites = sites.data;
    },

    handleAddNewField(formName) {
      this.loading = true;
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const field_coordinates = [];

          let payload = {
            name: this.form.name,
            site_id: this.form.site_id,
            field_coordinates: this.polygoneCoords
          };

          this.addField(payload)
            .then(() => {
              this.hideAddFieldDialog();
              this.clearForm();
              this.setShouldFetchFields(true);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },

        clearForm() {
      this.setObjectValues(this.form, "");
    },

    aaa(){
      this.$refs.mapRef.$mapPromise.then((map) => {
        var vm = this;
        const mouseupHandle = google.maps.event.addListener(map, 'click', function(event) {
          const marker = new google.maps.Marker({
            position: event.latLng,
            draggable: true,
            map: map
          });

          marker.addListener("dblclick", () => {
            vm.polygoneCoords = [];
            var myPolygon = new google.maps.Polygon({
                paths: vm.polygoneCoords,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35
              });
              myPolygon.setMap(null);
              // self.$emit('click', self.marker)

              vm.markers.forEach( (markerData) => {
                vm.polygoneCoords.push({
                  lat: Number(markerData.position.toString().replace(/([(\)])/g, "").split(', ')[0]),
                  lng: Number(markerData.position.toString().replace(/([(\)])/g, "").split(', ')[1]),
                  latitude: Number(markerData.position.toString().replace(/([(\)])/g, "").split(', ')[0]),
                  longitude: Number(markerData.position.toString().replace(/([(\)])/g, "").split(', ')[1])
                })
              })

              myPolygon = new google.maps.Polygon({
                paths: vm.polygoneCoords,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35
              });
              myPolygon.setMap(map);
          });
          vm.markers.push(marker)
          vm.cnt ++;
        });

      });
    },

    initMap(){
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.controls[this.google.maps.ControlPosition.RIGHT_CENTER] //.push(this.showTrailButton());
      });
    },

    async getBrowserLocation() {
      // if   - user is not super admin or admin, center the map to one bike belonging to the same cost center
      // else - point the admin and super admin to the browser location
      if (checkIfNotSuperAndAdministrator() && this.fields.length > 0) {
        this.center.lat = Number(this.fields[0].address_lat);
        this.center.lng = Number(this.fields[0].address_long);
      } else {
        navigator.geolocation.getCurrentPosition((location) => {
          this.center.lat = location.coords.latitude;
          this.center.lng = location.coords.longitude;
        });

        // if location is not working or turned off
        const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
        if (romaniaCoords) {
          this.center.lat = romaniaCoords.coords.latitude
          this.center.lng = romaniaCoords.coords.longitude
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .bike-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
</style>
