import axios from "axios";
import Jsona from "jsona";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const actions = {
  async getRoles({ commit }) {
    try {
      const response = await axios.get("/v1/roles");
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getRole({ commit }, id) {
    try {
      const response = await axios.get(`/v1/roles/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};
