import Vue from "vue";

const helpers = {
  showErrors(error) {
    let errors = [];

    if (error.response.data.errors) {
      errors = error.response.data.errors;
    } else if (!error.response.data.error) {
      let arrayBufferError = String.fromCharCode.apply(
        null,
        new Uint8Array(error.response.data)
      );

      errors = JSON.parse(arrayBufferError).errors;
    }

    let mappedErrors = errors.map(
      (error) => error.source + " -> " + error.details
    );

    Vue.prototype.$notify({
      title: "Error",
      dangerouslyUseHTMLString: true,
      message:
        mappedErrors.join("<br>") || error.response.data.error_description,
      type: "error",
      position: "top-left",
      customClass: "custom-notify",
    });

    return errors;
  },

  showSuccess(data) {
    Vue.prototype.$notify({
      title: "Success",
      message: data,
      type: "success",
      position: "bottom-right",
      customClass: "custom-notify",
    });
  },

  showWarning(data) {
    Vue.prototype.$notify({
      title: "Warning",
      message: data,
      type: "warning",
      position: "bottom-right",
      customClass: "custom-notify",
    });
  },
};

export default helpers;
