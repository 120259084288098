<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='soilSensors'
  )
    el-table-column(
      prop='name'
      :label="$t('sensors.transmitter_name')"
    )
      template(slot-scope='scope')
        span {{ scope.row.transmitter_name }}
    el-table-column(
      prop='name'
      :label="$t('sensors.number_type')"
    )
      template(slot-scope='scope')
        span.cursor-pointer.text-underline(@click="handleSoilSensor(scope.row)") {{ scope.row.sensor_number + " " +  handleSensorType(scope.row.sensor_type)}}
    //- el-table-column(
    //-   prop='actions'
    //-   :label="$t('fields.label.actions')"
    //- )
    //-   template(slot-scope='scope')
    //-     .grid-buttons
    //-       img.cursor-pointer.mr-2(
    //-         v-if="hideIcons()"
    //-         src="@assets/actions/delete.svg"
    //-         @click="handleDeleteFieldDialog(scope.row)"
    //-       )
    //-       img.cursor-pointer.mr-2(
    //-         v-if="hideIcons()"
    //-         src="@assets/actions/fix.svg"
    //-         @click="handleFieldInfoSettingsDialog(scope.row)"
    //-       )
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import store from "@state/store";
import { flaggedRidesStatuses, ridesStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "SoilSensorsList",
  mixins: [filterUtils, routerUtils, general],

  props: {
    soilSensors: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  data() {
    return {
      ridesStatuses,
      flaggedRidesStatuses,
      inBetween,
      dynamicViewsConstants
    }
  },
  watch: {
    allowDeletion() {
      this.handleDeleteField();
    },
  },
  methods: {
    ...mapMutations("soilSensor", ["setSoilSensor"]),
    handleSensorType(type){
      if(type)
        switch (type) {
          case '0e':
            return 'NPK';
          case 'ee':
            return 'THCNPK';
          case 'de':
            return 'THPHNPK';
          case '10':
            return 'PH';
          case 'c0':
            return 'TH';
          case 'e0':
            return 'THC';
          case 'f0':
            return 'THCPH';
          case 'd0':
            return 'THPH';
          case 'fe':
            return 'THCPHNPK';
          default:
            return 'THC';
        }
    },

    handleSoilSensor(sensor){
      this.setSoilSensor(sensor);
    },



    ...mapMutations("field", ["setField"]),
    ...mapActions("field", ["deleteField"]),

    ...mapMutations("dialog", [
      "showEditFieldInfoSettingsDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    hideIcons(){
      return (
        this.user &&
        [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
      // if(this.user.roles[0].name == roles.TENANT)
      //   return false;
      // if(this.user.roles[0].name == roles.ADMINISTRATOR && customerRole != roles.TENANT)
      //   return false;
      // return true;
    },

    async handleDeleteField() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteField(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteFieldDialog(field) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("fields.validations.delete_title")} ${field.name}`,
        dialogMessage: this.$t("fields.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(field);
    },

    handleFieldInfoSettingsDialog(field){
      this.setField(field);
      this.showEditFieldInfoSettingsDialog();
    }

  }
}
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
