<template lang="pug">
  .users-view
    h3 {{ $t('users.users') }}
    .filters
      FilterSiteDropdown(v-model="selectedSiteId")
    .users-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="true"
        )
          el-button.dark-green-btn(
            @click="handleOpenAddUserDialog"
          )
            h4 {{ $t('users.actions.add') }}
          //- el-button.dark-green-btn(
          //-   @click="goToWIthId('users/add_user', '')"
          //- )
          //-   h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          UsersViewTable(
            v-loading="loading"
            :users="users"
          )
    Pagination(
      :view="'users'"
      :search="search"
      :siteId="selectedSiteId"
      @viewData="handleGetUsers"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import FilterSiteDropdown from "@components/_shared/FilterSiteDropdown";
import { mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import UsersViewTable from "@components/users/UsersViewTable";

export default {
  components: { Pagination, SearchWithButtons, FilterSiteDropdown, UsersViewTable },
  mixins: [routerUtils, general],

  data() {
    return {
      activeName: "",
      users: [],
      search: "",
      loading: false,
      dynamicViewsConstants,
      selectedSiteId: "",
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("user", ["setSelectedTabRole"]),
    ...mapMutations("dialog", ["showAddUserDialog"]),

    handleGetUsers(data) {
      this.setSelectedTabRole(this.activeName);
      this.users = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    handleOpenAddUserDialog() {
      this.showAddUserDialog();
    },
  },
};
</script>
