<template lang="pug">
  .chart-component
    .chart-title
      span(v-if="this.averageDate.length > 0") 
        span {{ "Last 48 records for sensor " }}
        span <b>{{ this.sensor.sensor_number + handleSensorType(this.sensor.sensor_type) }}</b>
        span {{ " from " }}
        span(v-if="this.datetime  && this.datetime.length > 0") {{ "the selected date" }}
        span(v-else) <b>{{ this.averageDate }}</b>
    .chart-wrapper
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsHumidity"
          :series="chartHumidityInfo"
          :key="chartComponentKey"
        )
      br
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsTemperature"
          :series="chartTemperatureInfo"
          :key="chartComponentKey"
        )
      br
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsConductivity"
          :series="chartConductivityInfo"
          :key="chartComponentKey"
        )
      br
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsPh"
          :series="chartPhInfo"
          :key="chartComponentKey"
        )
      br
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsNitrogen"
          :series="chartNitrogenInfo"
          :key="chartComponentKey"
        )
      br
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsPhosphorus"
          :series="chartPhosphorusInfo"
          :key="chartComponentKey"
        )
      br
      .chart
        apexchart(
          type="line"
          height="200"
          width="95%"
          :options="chartClientOptionsPotassium"
          :series="chartPotassiumInfo"
          :key="chartComponentKey"
        )
</template>

<script>

import { chartClientOptions } from "@utils/generalUtils";
import { filterUtils } from "@utils/filterUtils";
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "SoilSensorsCharts",
  mixins: [filterUtils, general],

  props: {
    chartData: {
      type: Array,
      default: () => [],
    },

    chartType: {
      type: String,
    },

    sensor: {
      type: Object,
    },

    datetime: {
      type: Array,
      required: false,
    },
  },

  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      averageDate: "",

      chartHumidityInfo: [
        {
          name: this.$t("sensors.chart.humidity"),
          data: [],
        },
      ],
      chartTemperatureInfo: [
        {
          name: this.$t("sensors.chart.temperature"),
          data: [],
        },
      ],
      chartConductivityInfo: [
        {
          name: this.$t("sensors.chart.conductivity"),
          data: [],
        },
      ],
      chartPhInfo: [
        {
          name: this.$t("sensors.chart.ph"),
          data: [],
        },
      ],
      chartNitrogenInfo: [
        {
          name: this.$t("sensors.chart.nitrogen"),
          data: [],
        },
      ],
      chartPhosphorusInfo: [
        {
          name: this.$t("sensors.chart.phosphorus"),
          data: [],
        },
      ],
      chartPotassiumInfo: [
        {
          name: this.$t("sensors.chart.potassium"),
          data: [],
        },
      ],

      chartComponentKey: 0,
      chartClientOptionsHumidity: chartClientOptions,
      chartClientOptionsTemperature: chartClientOptions,
      chartClientOptionsConductivity: chartClientOptions,
      chartClientOptionsPh: chartClientOptions,
      chartClientOptionsNitrogen: chartClientOptions,
      chartClientOptionsPhosphorus: chartClientOptions,
      chartClientOptionsPotassium: chartClientOptions,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("soilSensor", ["soilSensor"]),
  },

  watch: {
    chartData() {
      this.mapInfosForChart();
    },
  },

  methods: {
    handleSensorType(type){
      if(type)
      switch (type) {
        case '0e':
          return 'NPK';
        case 'ee':
          return 'THCNPK';
        case 'de':
          return 'THPHNPK';
        case '10':
          return 'PH';
        case 'c0':
          return 'TH';
        case 'e0':
          return 'THC';
        case 'f0':
          return 'THCPH';
        case 'd0':
          return 'THPH';
        case 'fe':
          return 'THCPHNPK';
        default:
          return 'THC';
      }
    },

    mapInfosForChart() {
      this.chartComponentKey += 1;

      // reset the chart
      this.chartHumidityInfo[0].data = [];
      this.chartTemperatureInfo[0].data = [];
      this.chartConductivityInfo[0].data = [];
      this.chartPhInfo[0].data = [];
      this.chartNitrogenInfo[0].data = [];
      this.chartPhosphorusInfo[0].data = [];
      this.chartPotassiumInfo[0].data = [];

      this.chartClientOptionsHumidity.xaxis.categories = [];
      this.chartClientOptionsTemperature.xaxis.categories = [];
      this.chartClientOptionsConductivity.xaxis.categories = [];
      this.chartClientOptionsPh.xaxis.categories = [];
      this.chartClientOptionsNitrogen.xaxis.categories = [];
      this.chartClientOptionsPhosphorus.xaxis.categories = [];
      this.chartClientOptionsPotassium.xaxis.categories = [];

      const mappedDays = [];
      const timeStamps = [];

      // map the transactions to match apex data

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          timeStamps.push(info.created_at);
          mappedDays.push(this.handleTime(info.created_at));

          this.chartHumidityInfo[0].data.push(info.humidity);
          this.chartTemperatureInfo[0].data.push(info.temperature);
          this.chartConductivityInfo[0].data.push(info.conductivity);
          this.chartPhInfo[0].data.push(info.ph);
          this.chartNitrogenInfo[0].data.push(info.nitrogen);
          this.chartPhosphorusInfo[0].data.push(info.phosphorus);
          this.chartPotassiumInfo[0].data.push(info.potassium);

        });
        this.handleHumidity(mappedDays)
        this.handleTemperature(mappedDays)
        this.handleConductivity(mappedDays)
        this.handlePh(mappedDays)
        this.handleNitrogen(mappedDays)
        this.handlePhosphorus(mappedDays)
        this.handlePotassium(mappedDays)

        this.calculateAverageDate(timeStamps)
      }
    },

    handleTime(created_at){
      return `${new Date(created_at).getHours()}:${new Date(created_at).getMinutes()}:${new Date(created_at).getSeconds()}`
    },

    handleDate(created_at){
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      return `${new Date(created_at).getDate()} ${months[new Date(created_at).getMonth()]} ${new Date(created_at).getFullYear()}`
    },

    calculateAverageDate(timeStamps){
      const ts = timeStamps.map(time => new Date(time).getTime());

      const sortedDates = ts.sort((a, b) => a - b);
      const average =
        (sortedDates[sortedDates.length - 1] + sortedDates[0]) / 2;

      this.averageDate = this.handleDate(average)
    },

    handleHumidity(mappedDays){
      this.chartClientOptionsHumidity = {
        ...this.chartClientOptionsHumidity,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.humidity")
              },
            },
          },
        };
      this.chartClientOptionsHumidity.title = {text: 'Humidity'}
      this.chartClientOptionsHumidity.colors=['#73ccd8']
    },

    handleTemperature(mappedDays){
      this.chartClientOptionsTemperature = {
        ...this.chartClientOptionsTemperature,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.temperature")
              },
            },
          },
        };
      this.chartClientOptionsTemperature.title = {text: 'Temperature'}
      this.chartClientOptionsTemperature.colors=['#0463ca']
    },

    handleConductivity(mappedDays){
      this.chartClientOptionsConductivity = {
        ...this.chartClientOptionsConductivity,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.conductivity")
              },
            },
          },
        };
      this.chartClientOptionsConductivity.title = {text: 'Conductivity'}
      this.chartClientOptionsConductivity.colors=['#227722']
    },

    handlePh(mappedDays){
      this.chartClientOptionsPh = {
        ...this.chartClientOptionsPh,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.ph")
              },
            },
          },
        };
      this.chartClientOptionsPh.title = {text: 'Ph'}
      this.chartClientOptionsPh.colors=['#FFBF00']
    },

    handleNitrogen(mappedDays){
      this.chartClientOptionsNitrogen = {
        ...this.chartClientOptionsNitrogen,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.nitrogen")
              },
            },
          },
        };
      this.chartClientOptionsNitrogen.title = {text: 'Nitrogen'}
      this.chartClientOptionsNitrogen.colors=['#A922A9']
    },

    handlePhosphorus(mappedDays){
      this.chartClientOptionsPhosphorus = {
        ...this.chartClientOptionsPhosphorus,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.phosphorus")
              },
            },
          },
        };
      this.chartClientOptionsPhosphorus.title = {text: 'Phosphorus'}
      this.chartClientOptionsPhosphorus.colors=['#00AA00']
    },

    handlePotassium(mappedDays){
      this.chartClientOptionsPotassium = {
        ...this.chartClientOptionsPotassium,
        ...{
            xaxis: {
              categories: mappedDays,
            },
            yaxis: {
              title: {
                text: this.$t("sensor_infos.um.potassium")
              },
            },
          },
        };
      this.chartClientOptionsPotassium.title = {text: 'Potassium'}
      this.chartClientOptionsPotassium.colors=['#C9A0DC']
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.settings-btn {
  border-radius: 7px !important;
  background: $tab-view-color !important;
}
</style>
