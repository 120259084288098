import messageUtils from "@utils/messageUtils";
import axios from "axios";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  soilSensor: null,
  shouldFetchSoilSensors: false,
};

export const actions = {
  async getSoilSensors({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/soil_sensors${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addSoilSensor({ commit }, payload) {
    try {
      return axios.post(`/v1/sensor_transmitters/${payload.id}/add_sensor`);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateSoilSensor({ commit }, payload) {
    try {
      return axios.patch(`/v1/soil_sensors/${payload.id}`, { ...payload });
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setSoilSensor(state, soilSensor) {
    state.soilSensor = soilSensor;
  },

  setShouldFetchSoilSensors(state, value) {
    state.shouldFetchSoilSensors = value;
  },
};
