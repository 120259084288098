<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='transmitters'
    @selection-change="$emit('selectionChange', $event)"
  )
    el-table-column(
      prop='name'
      :label="$t('transmitters.label.name')"
      width="190px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.name ") {{ scope.row.name }}
        span(v-else) -
    el-table-column(
      prop='dev_eui'
      :label="$t('transmitters.label.dev_eui')"
      width="170px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.dev_eui") {{ scope.row.dev_eui }}
        span(v-else) -
    el-table-column(
      prop='site'
      :label="$t('transmitters.label.site')"
      width="130px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.site") {{ scope.row.site.name }}
        span(v-else) -
    el-table-column(
      prop='field'
      :label="$t('transmitters.label.field')"
      width="130px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.field") {{ scope.row.field.name }}
        span(v-else) -
    el-table-column(
      prop='location'
      :label="$t('transmitters.label.location')"
      width="130px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.latitude && scope.row.longitude")
          span.break-word(:title="$t('transmitters.latitude')") {{scope.row.latitude }}, 
          span.break-word(:title="$t('transmitters.longitude')") {{scope.row.longitude }}
        span(v-else) -
    el-table-column(
      prop='sensors_count'
      :label="$t('transmitters.label.sensors_count')"
      width="130px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.sensors_count >= 0") {{ scope.row.sensors_count }}
        span(v-else) -
    el-table-column(
      prop='actions'
      :label="$t('transmitters.label.actions')"
    )
      template(v-slot='scope')
        .grid-buttons
          //- img.cursor-pointer.ml-1(
          //-   src="@assets/preview.svg"
          //-   @click="handleViewBikeDetails(scope.row)"
          //- )
          img.cursor-pointer.ml-1(
            src="@assets/actions/edit.svg"
            :title="$t('transmitters.edit_transmitter')"
            @click="handleOpenEditTransmitter(scope.row)"
          )
          //- img.cursor-pointer.ml-1(
          //-   v-if="scope.row.can_be_deleted && hideIcon()"
          //-   src="@assets/actions/delete.svg"
          //-   @click="handleDeleteBikeDialog(scope.row)"
          //- )
          img.cursor-pointer.ml-1(
            src="@assets/actions/fix.svg"
            :title="$t('transmitters.manange_sensors')"
            @click="goToWIthNestedId('transmitters', scope.row.id, 'manage_sensors')"
          )
          //- img.cursor-pointer.ml-1(
          //-   src="@assets/actions/fix.svg"
          //-   @click="handleOpenManageSensors(scope.row)"
          //- )
          //- img.cursor-pointer.ml-1(
          //-   v-if="scope.row.dockingStationID && handleComputeUnlock(scope.row) && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status'])"
          //-   src="@assets/actions/unlock.svg"
          //-   @click="handleUnlockBike(scope.row)"
          //- )
          //- img.cursor-pointer.ml-1(
          //-   v-if="['previously_stolen_but_now_returned'].includes(scope.row.status)"
          //-   src="@assets/actions/found.svg"
          //-   @click="handleFoundBike(scope.row)"
          //- )
</template>

<script>
import {
  filterUtils,
  MAX_FIELD_LENGTH,
  trimTableField
} from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { joinValuesWithComma } from "@utils/generalUtils";
import { mapMutations, mapState } from "vuex";

export default {
  name: "TransmittersViewTable",
  mixins: [filterUtils, routerUtils,general],

  props: {
    transmitters: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showEditTransmitterDialog",
    ]),

    ...mapMutations("transmitter", ["setTransmitter"]),
    // ...mapMutations("soilSensor", ["setShouldFetchSoilSensors"]),

    handleOpenEditTransmitter(transmitter){
      this.showEditTransmitterDialog();
      this.setTransmitter(transmitter);
    },

    // trimTableField,
    // joinValuesWithComma,
    // ...mapMutations("dialog", [
    //   "showViewPasswordValidationDeleteDialog",
    //   "hideViewPasswordValidationDeleteDialog",
    // ]),

    // ...mapMutations("passwordValidationDelete", [
    //   "setDialogData",
    //   "setLoading",
    //   "setDataToDelete",
    //   "setAllowDeletion",
    // ]),

    // handleHWType(data){
    //   if (data == 20) {return "mechanical"}
    //   else if (data == 21) {return "electric"}
    //   else {return "unknown"}
    // },

    // handleBikeStateOfCharge(state_of_charge){
    //   if (state_of_charge == 1) return 'bikes.state_of_charge.fully_charged'
    //   if (state_of_charge == 2) return 'bikes.state_of_charge.medium_charged'
    //   if (state_of_charge == 3) return 'bikes.state_of_charge.low_battery'
    //   return 'bikes.state_of_charge.undefined'
    // },
  }
};
</script>

<style lang="scss" scoped>
.gdpr-message {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper {
  height: 293px;
  margin: 23px auto 40px;
  width: 100%;
}

.view-ride-details {
  padding-bottom: 24px;

  .price-plans {
    span {
      display: block;
      overflow-wrap: break-word;
    }
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
</style>
