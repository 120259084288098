<template lang="pug">
  .overview-view
      OverviewMap(
        v-if="loadedChartInfo"
        :sensors="[ ...sensors ]"
        :dateRange="dateRange"
      )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
// import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterFieldDropdown from "@components/_shared/FilterFieldDropdown";
import OverviewMap from "@components/client/overview/OverviewMap";
import { mapActions, mapState } from "vuex";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "OverviewView",
  mixins: [filterUtils, general],

  components: {
    // FilterDatePicker,
    Pagination,
    FilterFieldDropdown,
    OverviewMap,
    SearchWithButtons
  },

  data() {
    return {
      selectedFieldId: "",
      search: "",
      dateRange: [new Date(new Date().setHours(0,0,0,0)), new Date()],
      location: false,
      ridesInProgress: 0,
      sensors: [],
      allBikes: [],
      loadedChartInfo: true,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("field", ["selectedField", "selectedFieldInfoSettings"]),

  },

  async created(){
    if(this.selectedField){
      this.selectedFieldId = this.selectedField.id.toString();
      await this.handleGetMapSensors();
    }
  },

  watch: {
    search() {
      // console.log(this.search)
    },
    bikes() {
      // console.log(this.bikes)
    },

    async selectedField(){
      await this.handleGetMapSensors();
    },

    // async selectedFieldInfoSettings(){
    //   await this.handleGetMapSensors();
    // }
  },

  methods: {
    ...mapActions('map', ['getMapStatistics']),
    ...mapActions('soilSensor', ['getSoilSensors']),

    async handleGetMapSensors() {
      const response = await this.getSoilSensors(`?page_size=1000&by_field_id=${this.selectedField.id}`);
      this.sensors = response.data;
    },
  },
};
</script>

