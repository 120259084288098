import messageUtils from "@utils/messageUtils";
import axios from "axios";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  sensorInfo: null,
  shouldFetchSoilSensors: false,
};

export const actions = {
  async getSoilSensorInfo({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/soil_sensor_infos/show_statistics${payload}`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setSoilSensor(state, soilSensor) {
    state.soilSensor = soilSensor;
  },

  setShouldFetchSoilSensors(state, value) {
    state.shouldFetchSoilSensors = value;
  },
};
