import store from "@state/store";

export const roles = {
  SUPER_ADMINISTRATOR: "super_administrator",
  ADMINISTRATOR: "administrator",
  TENANT: "tenant"
};

export const checkIfNotSuperAndAdministrator = () => {
  return ![roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(
    (r) => store.state.auth.user.roles.map((role) => role.name).indexOf(r) >= 0
  );
};
