export const state = {

  isAddSiteDialogVisible: false,

  isEditSiteDialogVisible: false,

  isAddFieldDialogVisible: false,

  isViewUserDialogVisible: false,

  isAddNewUserDialogVisible: false,

  isViewRideDetailsDialogVisible: false,

  isViewPasswordValidationDelete: false,

  isAddUserDialogVisible: false,

  isEditUserDialogVisible: false,

  isEditFieldInfoSettingsDialogVisible: false,

  isEditTransmitterDialogVisible: false,

  isEditSensorDialogVisible: false,

  isViewSensorDialogVisible: false

};

export const mutations = {
  showViewPasswordValidationDeleteDialog() {
    state.isViewPasswordValidationDelete = true;
  },
  hideViewPasswordValidationDeleteDialog() {
    state.isViewPasswordValidationDelete = false;
  },

  showAddUserDialog() {
    state.isAddUserDialogVisible = true;
  },
  hideAddUserDialog() {
    state.isAddUserDialogVisible = false;
  },

  showEditUserDialog() {
    state.isEditUserDialogVisible = true;
  },
  hideEditUserDialog() {
    state.isEditUserDialogVisible = false;
  },

  showAddSiteDialog() {
    state.isAddSiteDialogVisible = true;
  },
  hideAddSiteDialog() {
    state.isAddSiteDialogVisible = false;
  },

  showEditSiteDialog() {
    state.isEditSiteDialogVisible = true;
  },
  hideEditSiteDialog() {
    state.isEditSiteDialogVisible = false;
  },

  showAddFieldDialog() {
    state.isAddFieldDialogVisible = true;
  },
  hideAddFieldDialog() {
    state.isAddFieldDialogVisible = false;
  },

  showEditFieldInfoSettingsDialog() {
    state.isEditFieldInfoSettingsDialogVisible = true;
  },

  hideEditFieldInfoSettingsDialog() {
    state.isEditFieldInfoSettingsDialogVisible = false;
  },

  showEditTransmitterDialog() {
    state.isEditTransmitterDialogVisible = true;
  },
  hideEditTransmitterDialog() {
    state.isEditTransmitterDialogVisible = false;
  },

  showEditSensorDialog() {
    state.isEditSensorDialogVisible = true;
  },

  hideEditSensorDialog() {
    state.isEditSensorDialogVisible = false;
  },

  showViewSensorDialog() {
    state.isViewSensorDialogVisible = true;
  },

  hideViewSensorDialog() {
    state.isViewSensorDialogVisible = false;
  },

  showViewUserDialog() {
    state.isViewUserDialogVisible = true;
  },
  hideViewUserDialog() {
    state.isViewUserDialogVisible = false;
  },

  showAddNewUserDialog() {
    state.isAddNewUserDialogVisible = true;
  },
  hideAddNewUserDialog() {
    state.isAddNewUserDialogVisible = false;
  },

};
