export const sidebarBlacklist = ["login", "forgot_password", "reset-password"];
export const navbarBlacklist = ["login", "forgot_password", "reset-password"];

export const routerUtils = {
  methods: {
    async goToWIthId(location, id) {
      await this.$router.push(`/${location}/${id}`);
    },
    async goToWIthNestedId(locationF, id, locationL) {
      await this.$router.push(`/${locationF}/${id}/${locationL}`);
    },
  },
};
