<template lang="pug">
  .details-view
    .details-list-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="true"
        )
      .details-list
        el-row
          el-col(:span='24')
            SoilSensorsList(
              v-loading="loading"
              :soilSensors="soilSensors"
            )
      .pagination
        Pagination(
          :view="'client_soil_sensors'"
          :fieldId="selectedFieldId"
          :search="search"
          @viewData="handleGetSoilSensors"
          @handleTableLoading="handleLoading"
        )
    .details-chart-wrapper
      .filters
        FilterDatePicker(
          v-model="dateRangee"
          @onChange="handleChange"
        )
      SoilSensorsCharts(
        :sensor="soilSensor"
        :chartData="sensorInfos"
        :chartType="chartType"
        :datetime="dateRangee"
      )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";

import SearchWithButtons from "@components/_shared/SearchWithButtons";
import SoilSensorsList from "@components/client/details/SoilSensorsList";
import Pagination from "@components/_shared/client/Pagination";
import SoilSensorsCharts from "@components/client/details/SoilSensorsCharts";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import { mapActions, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "DeatilsView",
  mixins: [filterUtils, general],

  components: {
    SearchWithButtons,
    SoilSensorsList,
    Pagination,
    SoilSensorsCharts,
    FilterDatePicker,
  },
  props: {
    dateRange: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      selectedFieldId: "",
      search: "",
      chartType: null,
      sensorInfos: null,

      // dateRangee: [new Date(new Date().setHours(0,0,0,0)), new Date()],
      dateRangee: [],
      location: false,
      ridesInProgress: 0,
      soilSensors: [],
      allBikes: [],
      loadedChartInfo: true,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("soilSensor", ["soilSensor"]),
    ...mapState("field", ["selectedField", "selectedFieldInfoSettings"]),

  },

  created(){
    if(this.selectedField){
      this.selectedFieldId = this.selectedField.id.toString();
    }
  },

  watch: {
    async dateRangee() {
      await this.handleGetSoilSensorInfo();
    },

    selectedField(){
      this.selectedFieldId = this.selectedField.id.toString();
    },

    async soilSensor() {
      await this.handleGetSoilSensorInfo()
    }

  },

  methods: {
    ...mapActions('map', ['getMapStatistics']),
    ...mapActions('sensorInfo', ['getSoilSensorInfo']),

    handleGetSoilSensors(data) {
      this.soilSensors = data;
    },

    async handleGetSoilSensorInfo() {
      let computedParams = `?page_size=48&by_soil_sensor_id=${this.soilSensor.id}`;
      if (this.dateRangee && this.dateRangee.length > 0) {

        let dateRangeComputed = {
          start_date: this.dateRangee[0],
          end_date: this.dateRangee[1],
        };

        computedParams += `&by_date_range[start_date]=${dateRangeComputed.start_date}&by_date_range[end_date]=${dateRangeComputed.end_date}`;
      }

      const response = await this.getSoilSensorInfo(computedParams);
      // const response = await this.getSoilSensorInfo(`?page_size=3&by_soil_sensor_id=${this.soilSensor.id}`);
      if (response.length > 0){
        this.sensorInfos = response.reverse();
      } else {
        this.sensorInfos = response;
      }
    },

    handleLoading(loading) {
      this.loading = loading
    },

    async handleChange(values) {
      let computedParams = `?page_size=48&by_soil_sensor_id=${this.soilSensor.id}`;
      if (values && values.length > 0) {
        let dateRangeComputed = {
          start_date: values[0],
          end_date: values[1],
        };

        computedParams += `&by_date_range[start_date]=${dateRangeComputed.start_date}&by_date_range[end_date]=${dateRangeComputed.end_date}`;
      }

      const response = await this.getSoilSensorInfo(computedParams);

      if (response.length > 0){
        this.sensorInfos = response.reverse();
      } else {
        this.sensorInfos = response;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.settings-btn {
  border-radius: 7px !important;
  background: $tab-view-color !important;
}
</style>
