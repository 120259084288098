<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='sites'
  )
    el-table-column(
      prop='siteName'
      :label="$t('sites.label.name')"
      width="200px"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.name && scope.row.name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.name) }}
        span(v-if="scope.row.name && scope.row.name.length <= MAX_FIELD_LENGTH") {{ scope.row.name }}
    el-table-column(
      prop='address'
      :label="$t('sites.label.address')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.address && scope.row.address.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.address"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.address) }}
        span(v-if="scope.row.address && scope.row.address.length <= MAX_FIELD_LENGTH") {{ scope.row.address }}
    el-table-column(
      prop='users'
      :label="$t('sites.label.tenants')"
      width="200px"
    )
      template(slot-scope='scope') 
        span(v-if="scope.row.users.length == 0") {{ "-" }}
        span(v-else) {{ listUsers(scope.row.users) }}
    el-table-column(
      prop='actions'
      :label="$t('sites.label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          img.cursor-pointer.mb-1.mr-2(
            src="@assets/actions/edit.svg"
            @click="handleOpenEditSiteDialog(scope.row)"
          )
          img.cursor-pointer.mb-1(
            src="@assets/actions/delete.svg"
            @click="handleDeleteSiteDialog(scope.row)"
          )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";


export default {
  name: "SitesViewTable",
  mixins: [general, routerUtils],

  props: {
    sites: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
      trimTableField,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  watch: {
    allowDeletion() {
      this.handleDeleteSite();
    },
  },

  methods: {
    ...mapActions("site", ["deleteSite"]),

    ...mapMutations("dialog", [
      "showAddSiteDialog",
      "showEditSiteDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("site", ["setSite"]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    listUsers(users){
      let userList = "";
      users.forEach((user) => {
        userList += user.email + ", ";
      })
      return userList;
    },

    hideSubmenu() {
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    handleOpenEditSiteDialog(site) {
      this.setSite(site);
      this.showEditSiteDialog();
    },

    handleDeleteSite() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteSite(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteSiteDialog(site) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("sites.validations.delete_title")} ${
          site.name
        }`,
        dialogMessage: this.$t("sites.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(site);
    },

  },
};
</script>

<style lang="scss" scoped>
.grid-buttons {
  display: flex;
  flex-wrap: wrap;
}
</style>
