<template lang="pug">
  el-submenu(
    index=''
  )
    template(slot='title')
      //- .img-wrapper
      //-   i.el-icon-s-tools(
      //-     style="font-size: 23px; margin-right: 0"
      //-   )
      .img-wrapper
        img(src="@src/assets/sidebar/cost_centers.svg")
      span {{ $t('sidebar.management') }}
    el-menu-item(index='/sites')
      router-link.sub-menu-link(to="/sites")
        span  {{ $t('sidebar.sites') }}
    el-menu-item(index='/fields')
      router-link.sub-menu-link(to="/fields")
        span  {{ $t('sidebar.fields') }}
    el-menu-item(index='/transmitters')
      router-link.sub-menu-link(to="/transmitters")
        span  {{ $t('sidebar.transmitters') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";

export default {
  name: "ManagementItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants,
      checkIfNotSuperAndAdministrator
    }
  },

  methods: {

  }
}
</script>
