<template lang="pug">
  #app
    HorizontalNavBar(v-if="!isAdmin")
    SideBar(v-if="isAdmin")
    .router-wrapper(v-bind:class = "(isAdmin)?'router-layout':''")
      RouterView(
        :key='$route.path'
        :class="{'container': !['login', 'forgot_password', 'change_password'].includes($route.name)}"
    )
    Dialogs
</template>

<script>
import SideBar from "@components/sidebar/SideBar";
import HorizontalNavBar from "@components/horizontalNavBar/HorizontalNavBar";
import { sidebarBlacklist, navbarBlacklist } from "@utils/router";
import Dialogs from "@components/dialogs/Dialogs";
import { mapActions, mapMutations, mapState } from "vuex";
import { sideMenuMixins } from "@src/mixins/sideMenu";
import { roles } from "@utils/roles";

const checkSidebarVisibility = (currentRouteName) =>
    sidebarBlacklist.includes(currentRouteName);

const checkNavbarVisibility = (currentRouteName) =>
    navbarBlacklist.includes(currentRouteName);

export default {
  name: "App",
  mixins: [sideMenuMixins],

  components: {
    Dialogs,
    SideBar,
    HorizontalNavBar,
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("site", ["clientSite"]),
  },

  data() {
    return {
      isClient: null,
      isAdmin: null,
      hideSidebar: false,
      hideNavbar: false
    };
  },

  watch: {
    $route(newVal) {
      // this.hideSidebar = checkSidebarVisibility(newVal.name);
      // this.hideNavbar = checkNavbarVisibility(newVal.name);
    },
    async user() {
      await this.handleUser();
    }
  },

  methods: {
    ...mapActions("auth", ["sessionUser"]),
    ...mapActions("site", ["getSite"]),

    ...mapMutations("site", ["setClientSite"]),

    showSidebar() {
      if(this.user)
        return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
      return 0;
    },

    async handleUser(){
      // client
      if(this.user) {
        if(!this.hideNavbar && !this.showSidebar()) {
          // this.isClient = true;
          this.isAdmin = false;
          await this.handleClientSite();
        }else 
        // admin
        if(!this.hideSidebar && this.showSidebar()){
          // this.isClient = false;
          this.isAdmin = true;
        }
      }else
      {
        this.isAdmin = false;
      }

    },

    async handleClientSite(){
      const clientSite = await this.getSite(this.user.sites[0].id)
      this.setClientSite(clientSite.data)
    }
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  min-height: 100vh;
}
.router-layout{
  padding-left: 270px
}
.router-wrapper {
  transition: 0.4s;
  width: 100%;
  overflow: hidden;
}
</style>
