<template lang="pug">
  el-table(
    style="width: 100%;"
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data="soilSensors"
  )
    el-table-column(
      prop="sensorNumber"
      :label="$t('sensors.label.sensor_number')"
    )
      template(slot-scope='scope')
        span {{ scope.row.sensor_number }}
    el-table-column(
      prop="sensorType"
      :label="$t('sensors.label.sensor_type')"
    )
      template(slot-scope='scope')
        span {{ handleSensorType(scope.row.sensor_type) }}
    el-table-column(
      prop="latitude"
      :label="$t('sensors.label.latitude')"
    )
      template(slot-scope='scope')
        span(v-if='scope.row.latitude') {{ scope.row.latitude }}
        span(v-else) -
    el-table-column(
      prop="longitude"
      :label="$t('sensors.label.longitude')"
    )
      template(slot-scope='scope')
        span(v-if='scope.row.longitude') {{ scope.row.longitude }}
        span(v-else) -
    el-table-column(
      prop="sensingRadius"
      :label="$t('sensors.label.sensing_radius')"
    )
      template(slot-scope='scope')
        span {{ scope.row.sensing_radius }}
    el-table-column(
      prop="sensingRadius"
      :label="$t('sensors.label.altitude')"
    )
      template(slot-scope='scope')
        span {{ scope.row.altitude }}
    el-table-column(
      prop='actions'
      :label="$t('sensors.label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          img.cursor-pointer.mb-1.mr-2(
            src="@assets/actions/edit.svg"
            @click="handleOpenEditSensorDialog(scope.row)"
          )
          img.cursor-pointer.mb-1.mr-2(
            src="@assets/preview.svg"
            @click="handleOpenViewSensorDialog(scope.row)"
          )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";

export default {
  name: "SoilSensorsViewTable",
  mixins: [filterUtils],

  props: {
    soilSensors: {
      type: Array
    },
  },
  data() {
    return {
      sensors: [],
      loadingTable: false,
      loadingAddBtn: false,

      transmitterId: null,

      loading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  watch: {
    soilSensors(){
      // console.log('sdsdsddsdssxcc')
    }
  },

  methods: {
    ...mapMutations('soilSensor', ['setSoilSensor']),
    ...mapMutations('dialog', ['showEditSensorDialog', 'showViewSensorDialog']),

    // handleGetSoilSensors(data) {
    //   this.soilSensors = data
    // },
    handleSensorType(type){
      if(type)
        switch (type) {
          case '0e':
            return 'NPK';
          case 'ee':
            return 'THCNPK';
          case 'de':
            return 'THPHNPK';
          case '10':
            return 'PH';
          case 'c0':
            return 'TH';
          case 'e0':
            return 'THC';
          case 'f0':
            return 'THCPH';
          case 'd0':
            return 'THPH';
          case 'fe':
            return 'THCPHNPK';
          default:
            return 'THC';
        }
    },

    handleLoading(loading) {
      this.loadingTable = loading
    },

    handleOpenEditSensorDialog(sensor){
      this.setSoilSensor(sensor);
      this.showEditSensorDialog();
    },

    handleOpenViewSensorDialog(sensor){
      this.setSoilSensor(sensor);
      this.showViewSensorDialog();
    }

  }
};
</script>

<style lang="scss" scoped>
.grid-buttons {
  display: flex;
  flex-wrap: wrap;
}
</style>
