<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='users'
  )
    el-table-column(
      prop='first_name'
      :label="$t('users.label.full_name')"
    )
      template(slot-scope='scope')
        span {{ scope.row.first_name + " " + scope.row.last_name}}
    el-table-column(
      prop='email'
      :label="$t('users.label.email_address')"
      width="350px"
    )
      template(slot-scope='scope')
        span {{ scope.row.email }}
    el-table-column(
      prop='phone_number'
      :label="$t('users.label.phone_number')"
    )
      template(slot-scope='scope')
        span {{ scope.row.phone_number }}
    el-table-column(
      prop='cost_center_id'
      :label="$t('users.label.role_and_site_name')"
    )
      template(slot-scope='scope')
        span <b>{{ scope.row.role_names[0] }}</b>
        span(v-if="scope.row.site_names.length != 0") {{ ": " + scope.row.site_names[0] }}
    el-table-column(
      prop='actions'
      :label="$t('users.label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          //- img.cursor-pointer.mr-2(
          //-   v-if="hideIcon()"
          //-   src="@assets/preview.svg"
          //-   @click="goToWIthId('users', scope.row.id)"
          //- )
          img.cursor-pointer.mr-2(
            v-if="hideIcons(scope.row.role_names[0])"
            src="@assets/actions/edit.svg"
            @click="handleOpenEditUserDialog(scope.row)"
          )
          //- i.el-icon-refresh-left.cursor-pointer.mr-2(
          //-   v-loading="loadingResetUserPassword"
          //-   @click="handleResetUserPassword(scope.row.id)"
          //-   style="font-size: 35px; margin-right: 0"
          //- )
          img.cursor-pointer.mr-2(
            v-if="hideIcons(scope.row.role_names[0])"
            src="@assets/actions/delete.svg"
            @click="handleDeleteUserDialog(scope.row)"
          )
</template>

<script>
import { dynamicViewsConstants } from "@src/constants";
import { MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { roles } from "@utils/roles";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";

export default {
  name: "UsersViewTable",
  mixins: [routerUtils, general],

  props: {
    users: {
      type: Array,
    },
  },

  data() {
    return {
      loadingResetUserPassword: false,

      dynamicViewsConstants,
      MAX_FIELD_LENGTH,
      roles,
      trimTableField,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteUser();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "allowDeletion",
      "dataToDelete",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showEditUserDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapMutations("user", ["setUser", "setCustomer"]),

    ...mapActions("user", ["deleteUser", "getUsers", "resetUserPassword"]),

    hideIcon(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    hideIcons(customerRole){
      if(this.user.roles[0].name == roles.TENANT)
        return false;
      if(this.user.roles[0].name == roles.ADMINISTRATOR && customerRole != roles.TENANT)
        return false;
      return true;
    },

    async handleDeleteUser() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteUser(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteUserDialog(user) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("users.validations.delete_title")} ${
          user.first_name
        } ${user.last_name}`,
        dialogMessage: this.$t("users.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(user);
    },

    async handleResetUserPassword(id) {
      this.loadingResetUserPassword = true;
      await this.resetUserPassword(id);
      this.loadingResetUserPassword = false;
    },

    handleOpenEditUserDialog(user){
      this.setCustomer(user);
      this.showEditUserDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-buttons {
  display: inline-grid;
  // grid-template-rows: 40px 40px 40px;
  grid-template-columns: 40px 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }

  i.el-icon-refresh-left {
    margin: auto 0;
  }
}
</style>
