export const usersViewValidations = {
  data() {
    return {
      addUserFormRules: {
        full_name: [
          {
            required: true,
            validator: this.fullNameValidator,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            validator: this.phoneNumberValidator,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: this.$t("users.validations.enter_password"),
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            message: this.$t("users.validations.enter_role"),
            trigger: "blur",
          },
        ],
        site_id: [
          {
            required: true,
            validator: this.siteValidator,
            trigger: "blur",
          },
        ]
      },

      editUserFormRules: {
        full_name: [
          {
            required: true,
            validator: this.fullNameValidator,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            validator: this.phoneNumberValidator,
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            message: this.$t("users.validations.enter_role"),
            trigger: "blur",
          },
        ],
        site_id: [
          {
            required: true,
            validator: this.siteValidator,
            trigger: "blur",
          },
        ]
      },
    };
  },
};
