<template lang="pug">
  .add-site
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'AddSite'"
    )
    el-form(
      :model="form"
      ref="AddSiteForm"
      :rules="addSiteRules"
      @submit.native.prevent
    )
      el-row
        el-col(center)
          el-row(:gutter="24")
            el-col(:span="8")
              el-form-item(
                prop="name"
                :label="$t('sites.actions.label.name')"
              )
                el-input(
                  v-model.number="form.name"
                  name="name"
                )
            el-col.address(:span="8")
              el-form-item(
                prop="address"
                :label="$t('sites.actions.label.address')"
              )
                el-autocomplete(
                  ref="autocomplete"
                  v-model="form.address"
                  name="address"
                  :trigger-on-focus="false"
                  @select="handleSelectAddress"
                  :fetch-suggestions="addressSearchResultsDebounce"
                )
    el-row.text-center
      el-button.dark-green-btn.mt-5.pop-up-btn(
        @click="handleAddNewSite('AddSiteForm')"
        :loading="loading"
      ) {{ $t('common.add') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "../../_shared/GoogleMaps";
import { customValidations } from "@utils/customValidators";
import { SitesViewValidations } from "@utils/formValidations/sitesViewValidations";
import { currencyOptions, setObjectValues } from "@utils/generalUtils";
import _ from "lodash";
import Vue from "vue";

export default {
  name: "AddSite",
  mixins: [SitesViewValidations, customValidations, currencyOptions],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      selectedPlaceID: null,
      foundPlacesByPin: [],
      form: {
        name: null,
        address: null,
        address_long: null,
        address_lat: null,
      },

      loading: false,
      setObjectValues,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["changedDialog"])
  },

  created() {
    // set empty map coordinates to reset the map
    this.setMapCoordinates({ lat: null, lng: null })
  },

  methods: {
    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapActions("site", ["addSite"]),

    ...mapMutations("map", ["setMapCoordinates"]),

    ...mapMutations("dialog", ["hideAddSiteDialog"]),

    ...mapMutations("site", [
      "setSite",
      "setShouldFetchSites",
    ]),

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
            address,
            autocompleteCallback
        ) {
          this.handleGetPlaceData(address, autocompleteCallback);
        },
        300),

    async handleSelectAddress(item) {
      this.selectedPlaceID = item.place_id;
      const placeDetails = await this.getPlaceByID(item.place_id);

      this.form.address_lat = placeDetails.lat;
      this.form.address_long = placeDetails.lng;
      let coordinates = {
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      };

      await this.setMapCoordinates(coordinates);
    },

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      this.form.address = address;
      this.form.address_lat = markerPosition.lat;
      this.form.address_long = markerPosition.lng;

      this.$refs.autocomplete.focus();
    },

    handleAddNewSite(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.addSite(params)
              .then(() => {
                this.hideAddSiteDialog();
                this.clearForm();
                this.setShouldFetchSites(true);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>