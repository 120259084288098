import axios from "axios";
import Jsona from "jsona";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  site: null,
  clientSite: null,
  shouldFetchSites: false
};

export const actions = {
  async getSites({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/sites${payload}`);
      console.log(response)
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getSite({ commit }, id) {
    try {
      const response = await axios.get(`/v1/sites/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addSite({ commit }, payload) {
    try {
      await axios.post("/v1/sites", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateSite({ commit }, payload) {
    try {
      await axios.patch(`/v1/sites/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_updated"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteSite({ commit }, payload) {
    try {
      await axios.delete(`/v1/sites/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setSite(state, site) {
    state.site = site;
  },

  setClientSite(state, clientSite) {
    state.clientSite = clientSite;
  },

  setShouldFetchSites(state, value) {
    state.shouldFetchSites = value;
  }
};
