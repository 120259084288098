<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='fields'
  )
    el-table-column(
      prop='name'
      :label="$t('fields.label.name')"
    )
      template(slot-scope='scope')
        span.cursor-pointer.text-underline {{ scope.row.name }}
    el-table-column(
      prop='site'
      :label="$t('fields.label.site_name')"
    )
      template(slot-scope='scope')
        span.cursor-pointer.text-underline {{ scope.row.site.name }}
    el-table-column(
      prop='actions'
      :label="$t('fields.label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          //- img.cursor-pointer.mr-2(
          //-   v-if="hideIcons()"
          //-   src="@assets/actions/edit.svg"
          //-   @click="handleFieldInfoSettingsDialog(scope.row)"
          //- )
          img.cursor-pointer.mr-2(
            v-if="hideIcons()"
            src="@assets/actions/delete.svg"
            @click="handleDeleteFieldDialog(scope.row)"
          )
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import store from "@state/store";
import { flaggedRidesStatuses, ridesStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "FieldsTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    fields: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  data() {
    return {
      ridesStatuses,
      flaggedRidesStatuses,
      inBetween,
      dynamicViewsConstants
    }
  },
  watch: {
    allowDeletion() {
      this.handleDeleteField();
    },
  },
  methods: {
    ...mapMutations("field", ["setField"]),
    ...mapActions("field", ["deleteField"]),

    ...mapMutations("dialog", [
      "showEditFieldInfoSettingsDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    hideIcons(){
      return (
        this.user &&
        [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
      // if(this.user.roles[0].name == roles.TENANT)
      //   return false;
      // if(this.user.roles[0].name == roles.ADMINISTRATOR && customerRole != roles.TENANT)
      //   return false;
      // return true;
    },

    async handleDeleteField() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteField(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteFieldDialog(field) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("fields.validations.delete_title")} ${field.name}`,
        dialogMessage: this.$t("fields.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(field);
    },

    handleFieldInfoSettingsDialog(field){
      this.setField(field);
      this.showEditFieldInfoSettingsDialog();
    }

  }
}
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
