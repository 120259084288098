<template lang="pug">
  .bikeing-station-map-wrapper
    GmapMap.custom-map(
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="width: 400px; height: 300px"
    )

</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { inBetween } from "@utils/filterUtils";
import { mapActions, mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";

export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      polygons: [],
      markers: [],
      fieldsInfo: [],
      infowindow: [],
      zoom: 15,
      center: {
        lat: 0,
        lng: 0,
      },
      
      inBetween,
    };
  },

  watch: {
    // async google() {
    //   await this.drawPolygons();
    // },
    async fields() {
      this.drawPolygons();
    },

  },

  computed: {
    google: gmapApi,

    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.getBrowserLocation();
  },

  async mounted() {
    // this.initMap();
    this.drawPolygons();
  },

  methods: {

    initMap(){
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.controls[this.google.maps.ControlPosition.RIGHT_CENTER] //.push(this.showTrailButton());
      });
    },

    drawPolygons() {
      this.fieldsInfo = [];
      var polygoneCoords = [];


      this.markers.map((marker) => marker.setMap(null))
      this.polygons.map((polygon) => polygon.setMap(null))
               
      this.markers = []

      this.$refs.mapRef.$mapPromise.then((map) => {
        this.fields.forEach((field) => {
          polygoneCoords = [];
          field.field_coordinates.forEach( (field_coordinate) => {
            polygoneCoords.push({
              lat: Number(field_coordinate.latitude.toString()),
              lng: Number(field_coordinate.longitude.toString())
            })
          })

          var myPolygon = new google.maps.Polygon({
            paths: polygoneCoords,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            label: "yyyyy"
          });

          myPolygon.setMap(map);

          const contentString =
            '<div id="content" class="info-box">' +
            '<div class="hr-custom"></div>' +
            '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
            `<h4 class="pt-2 pb-2"></h4>
              <p class="margin-0 align-items-center justify-content-center font-weight-normal mb-2"><b>Name:</b> ${field.name}</p>
              <p class="margin-0 align-items-center justify-content-center font-weight-normal mb-2"><b>Site:</b> ${field.site.name}</p>
              ` +
            "</div>" +
            "</div>";

          const infoWindow = new this.google.maps.InfoWindow({
            content: contentString,
            shouldFocus: false,
            disableAutoPan: true,
          });

          this.polygons.push(myPolygon)

            let marker = null;

            marker = new this.google.maps.Marker({
              position: {
                lat: Number(field.field_coordinates[0].latitude),
                lng: Number(field.field_coordinates[0].longitude),
              },
              map: map,
              draggable: false,
            });

            marker.addListener("click", () => {
              map.panTo(marker.getPosition());
              map.setZoom(18);
              infoWindow.open({
                anchor: marker,
                shouldFocus: false,
              });
            });

            this.markers.push(marker)
        })
        // this.markers.map((marker) => marker.setMap(map))
      });
    },

    infoBoxContent(field){

      const contentString =
        '<div id="content" class="info-box">' +
        '<div id="siteNotice">' +
        "</div>" +
        // this.renderBikeInfoForTracker(bikeInfo) +
        // this.renderTrackerInfoForBike(trackerInfo) +
        '<div class="hr-custom"></div>' +
        '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
        `<h4 class="pt-2 pb-2"></h4>
          <p class="margin-0 align-items-center justify-content-center font-weight-normal mb-2">${field.name}</p>

          ` +
        "</div>" +
        "</div>";
            // ${this.renderInfoBoxStatus(bikeInfo)}
      return contentString;

    },

    async getBrowserLocation() {
      // if   - user is not super admin or admin, center the map to one bike belonging to the same cost center
      // else - point the admin and super admin to the browser location
      if (checkIfNotSuperAndAdministrator() && this.bikes.length > 0) {
        this.center.lat = Number(this.bikes[0].address_lat);
        this.center.lng = Number(this.bikes[0].address_long);
      } else {
        navigator.geolocation.getCurrentPosition((location) => {
          this.center.lat = location.coords.latitude;
          this.center.lng = location.coords.longitude;
        });

        // if location is not working or turned off
        const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
        if (romaniaCoords) {
          this.center.lat = romaniaCoords.coords.latitude
          this.center.lng = romaniaCoords.coords.longitude
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .bike-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
</style>
