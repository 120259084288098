<template lang="pug">
  div
    el-menu-item(
      route index='/users'
    )
      template(slot='title')
        router-link.sub-menu-link(to="/users")
          .img-wrapper
            img(src="@src/assets/sidebar/users.svg")
          span  {{ $t('sidebar.users') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";

export default {
  name: "UsersItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
