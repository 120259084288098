<template lang="pug">
  .settings-view
    //- h3 {{ $t('settings.settings') }}
    el-row.d-flex.justify-content-center( :gutter="50" style="margin-top: 10px")
      el-col(:span="10")
        ProfileDataForm
      el-col(:span="10")
        PasswordChangeForm
</template>

<script>
import ProfileDataForm from "@/src/components/settings/ProfileDataForm";
import PasswordChangeForm from "@components/settings/PasswordChangeForm";

export default {
  name: "SettingsView",
  components: { ProfileDataForm, PasswordChangeForm },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.settings-btn {
  border-radius: 7px !important;
  background: $tab-view-color !important;
}
</style>
