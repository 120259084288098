<template lang="pug">
  .map-wrapper
    .buttons
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/humidity2.png"
        :title="$t('overview.map.humidity')"
        @click="showInfoBy('humidity')"
      )
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/temperature.png"
        :title="$t('overview.map.temperature')"
        @click="showInfoBy('temperature')"
      )
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/conductivity.png"
        :title="$t('overview.map.conductivity')"
        @click="showInfoBy('conductivity')"
      )
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/ph.png"
        :title="$t('overview.map.ph')"
        @click="showInfoBy('ph')"
      )
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/nitrogen.png"
        :title="$t('overview.map.nitrogen')"
        @click="showInfoBy('nitrogen')"
      )
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/phosphorus.png"
        :title="$t('overview.map.phosphorus')"
        @click="showInfoBy('phosphorus')"
      )
      img.cursor-pointer.ml-1(style="width:2%;height:2%"
        src="@assets/client/overview/potassium.png"
        :title="$t('overview.map.potassium')"
        @click="showInfoBy('potassium')"
      )
    GoogleMaps(
      :dialogName="'Overview'"
      :sensors="sensors"
      :soilParam="soilParam"
    )
        //- ref="mapRef"
        //- :center="center"
        //- :zoom="zoom"
        //- map-type-id="roadmap"
        //- style="width: 400px; height: 300px"
</template>

<script>
import customMarkerAvailable from "@assets/dockingStationsMap/available-location.svg";
import { gmapApi } from "vue2-google-maps";
import { dockingStationsStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";
import bike from '@assets/dockingStationsMap/bike.svg'
import { mapState, mapActions } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";

import GoogleMaps from "@components/_shared/client/GoogleMaps";

export default {
  name:"Overview",

  components: {
    GoogleMaps,
  },
  props: {
    sensors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      markers: [],
      zoom: 15,
      center: {
        lat: 0,
        lng: 0,
      },

      field_setting: [
        {
          type: null,
          info: [],
        }
      ],

      soilParam: "humidity",

      dockingStationsStatuses,
      inBetween,
    };
  },

  watch: {
    google() {
      // this.addMapMarkers();
    },
    sensors(){
      // this.addMapMarkers();
    }
  },

  computed: {
    google: gmapApi,

    ...mapState("auth", ["user"]),
    ...mapState("field", ["selectedField", "selectedFieldInfoSettings"]),

  },

  async created() {
    await this.getBrowserLocation();
  },

  methods: {
    ...mapActions("field", ["getFieldInfoSettings"]),

    async showInfoBy(param){
      this.soilParam = param;
      // if(this.soilParam != param){
      //   const response =  await this.getFieldInfoSettings(`?by_field_id=${this.selectedField.id}&by_info_type=${param}`)
      //   this.soilParam = response.data[0]
      // }
      // else {
      //   this.soilParam = null;
      // }
    },

    async getBrowserLocation() {
      // if   - user is not super admin or admin, center the map to one dock belonging to the same cost center
      // else - point the admin and super admin to the browser location
      if (checkIfNotSuperAndAdministrator() && this.sensors.length > 0) {
        this.center.lat = Number(this.sensors[0].address_lat);
        this.center.lng = Number(this.sensors[0].address_long);
      } else {
        navigator.geolocation.getCurrentPosition((location) => {
          this.center.lat = location.coords.latitude;
          this.center.lng = location.coords.longitude;
        });

        // if location is not working or turned off
        const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
        if (romaniaCoords) {
          this.center.lat = romaniaCoords.coords.latitude
          this.center.lng = romaniaCoords.coords.longitude
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.buttons {
  // display: block;
  // float: right !important;
  // display: inline-grid;
  // grid-template-rows: 40px 40px;
  // grid-template-columns: 40px 40px;
  // grid-gap: 3px;

  img {
    margin: auto 0;
  }
}

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .dock-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
</style>
