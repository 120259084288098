<template lang="pug">
  el-menu-item(index='/details')
    router-link.sub-menu-link(to="/details")
      .img-wrapper
        img(style="height: 80%; width: 80%" src="@src/assets/navbar/details-list.svg")
      span  {{ $t('navbar.details') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "NavDetailsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
