<template lang="pug">
  el-menu-item(index='/overview')
    router-link.sub-menu-link(to="/overview")
      .img-wrapper
        img(style="height: 100%; width: 100%" src="@src/assets/navbar/overview-map.svg")
      span  {{ $t('navbar.overview') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "NavOverviewItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
