<template lang="pug">
  .fields-view
    h3 {{ $t('fields.fields') }}
    .tab-view
      FilterTabs(
        v-model="activeName"
        :view="'fields_map'"
        :tabs="tabs"
      )
    .filters
      FilterSiteDropdown(v-model="selectedSiteId")
    .field-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="true"
        )
          el-button.dark-green-btn(
            v-if="activeName == 'fields_list'"
            @click="handleAddFieldDialog"
          )
            h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          FieldsMap(
            v-if=`activeName == 'fields_map'`
            :fields="[...fields]"
          )
        el-col(:span='24')
          FieldsTable(
            v-if=`activeName == 'fields_list'`
            v-loading="loading"
            :fields="fields"
            @selectionChange="handleSelectionChange"
          )
    Pagination(
      :view="`${viewName}`"
      :search="search"
      :siteId="selectedSiteId"
      @viewData="handleDataForPagination"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterTabs from "@components/_shared/FilterTabs";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
// import InventoryViewTable from "@components/bikes_inv/InventoryViewTable";
// import TrackerViewTable from "@components/bikes_inv/TrackerViewTable";

import FieldsMap from "@components/fields/FieldsMap";
import FieldsTable from "@components/fields/FieldsTable";
import FilterSiteDropdown from "@components/_shared/FilterSiteDropdown";

export default {
  name: "Inventory",
  mixins: [filterUtils, general],
  components: {
    Pagination, 
    FilterTabs,
    SearchWithButtons,
    // InventoryViewTable,
    // TrackerViewTable,
    FieldsMap,
    FieldsTable,
    FilterSiteDropdown
  },

  data() {
    return {
      selectedSiteId: "",
      search: "",
      tabs: [
        { name: "fields_map", fields: [] },
        { name: "fields_list", fields: [] }
      ],
      activeName: 'fields_map',
      viewName: 'fields_map',
      trackers: [],
      dateRange: [],
      fields: [],
      multipleSelection: [],
      loading: false,
      dynamicViewsConstants
    };
  },
  watch: {
    activeName(){
       this.viewName = this.activeName
    //   // if(this.activeName == 'all'){
    //   //   handleGetInventory()
    //   // this.handleDataForPagination()
    //   // }
    },
    //  selectedSiteId(){
    //         this.handleDataForPagination()
    //  }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("bike", ["deleteBike"]),

    ...mapMutations("dialog", [
      "showAddFieldDialog"
    ]),

    // ...mapMutations("bike", ["setSelectedBikes"]),

    handleDataForPagination(data){
      this.fields = data;
    },

    handleAddFieldDialog() {
      this.showAddFieldDialog();
    },

    // handleGetInventory(data) {
    //   this.bikes = data;
    // },

    // handleGetTrackers(data) {
    //   this.trackers = data;
    // },

    handleLoading(loading) {
      this.loading = loading;
    },

    handleSelectionChange(value) {
      this.multipleSelection = value;
      this.setSelectedBikes(this.multipleSelection);
    },

    handleOpenAssignCostCenterDialog() {
      if (this.multipleSelection.length > 0) {
        this.showViewAssignCostCenterDialog();
      } else {
        this.$message({
          type: "warning",
          message: this.$t("inventory.validations.select_bikes"),
        });
      }
    },
  },
};
</script>
