<template lang="pug">
  el-card.nav-profile-wrapper.position-relative(v-if="user")
    .d-flex
      img.logo-wrapper(src="@src/assets/navbar/logo1.png")
      //- img.profile-img(:src="user.image_url")
      .wrapper.ml-3
        div {{ user.first_name + " " + user.last_name }}
        //- .switch-cost-center(v-if="checkIfNotSuperAndAdministrator()")
        //-   .cost-center.d-flex.align-items-center.justify-space-between.cursor-pointer.mt-2(@click="showSwitchCostCenterDialog")
        //-     span(v-if="user.selected_cost_center") {{ user.selected_cost_center.name }}
        //-     span(v-else) -
        //-     img(src="@src/assets/sidebar/dropdown_white.svg")
        //-   .roles.d-flex.mt-1
        //-     span(v-for="roleName in computeRolesOnSelectedCostCenter") {{ roleName }}
        h5.mt-3.text-white.w-fit-content(@click="handleLogout") {{ $t('auth.logout') }}
    span.nav-arrow.d-flex.align-items-center.justify-content-center.cursor-pointer(
      :class="{'closed': !isMenuOpen}"
      
    )
    //- @click="$emit('toggleMenuOpen', !isMenuOpen)"
      //- img(:src="require('@assets/_shared/double-arrow.svg')")
    .open-menu.flex-direction-column.align-items-center.cursor-pointer.p-2(
      :class="{'open': !isMenuOpen}"
      @click="$emit('toggleMenuOpen', !isMenuOpen)"
    )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";

export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      checkIfNotSuperAndAdministrator
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    computeRolesOnSelectedCostCenter() {
      if (this.user.selected_cost_center) {
        const filteredRoles = this.user.user_role_cost_centers.filter(userRoleCostCenter => {
          return userRoleCostCenter.cost_center.id === this.user.selected_cost_center.id
        })

        return filteredRoles.map(filteredRole => filteredRole.role.name)
      }
    }
  },

  methods: {
    ...mapMutations("dialog", [
      "showSwitchCostCenterDialog",
    ]),

    ...mapActions({
      logout: "auth/logout",
    }),

    handleLogout() {
      this.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },
    
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.nav-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 24px;
  user-select: none;
  padding: 20px;
  margin-top: -40px;

  &.closed {
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    margin-top: 25px;
    margin-right: 40px;
    z-index: 1;
    height: 10px;
    width: 10px;
    background-image: linear-gradient(#2564f0, #3d38ed);
    border-radius: 50%;
    transition: all 1s ease-in-out;
  }
}

.open-menu {
  position: fixed;
  top: 140px;
  right: 0;
  background: $gradient-start;
  border-radius: 0px 4px 4px 0px;
  opacity: 0;
  user-select: none;
  display: flex;

  > img {
    width: 20px;
  }

  &.open {
    animation-name: slide;
    animation-duration: 0.3s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;

    @keyframes slide {
      from {
        right: 0;
        opacity: 0;
      }
      to {
        right: -64px;
        opacity: 1;
      }
    }
  }
}

.el-card {
  border-radius: 0;
}

.profile-img {
  max-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 25%;
  object-fit: cover;
}

.nav-profile-wrapper {
  padding: 5px 0;
  margin-bottom: 10px;
  background-color: transparent;
  align-content: center;
  text-align: left;
  border-style: hidden hidden solid hidden;
  border-color: $gray;

  .roles {
    flex-direction: column;
  }

  span,
  div {
    color: $white;
  }

  .el-icon-setting {
    font-size: 23px;
    color: white;
  }

  .roles {
    span {
      font-size: 14px;
      color: $lighter-gray;
    }
  }

  h5 {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
