<template lang="pug">
  .edit-site
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'EditTransmitter'"
    )
    el-form(
      :model="form"
      v-loading="loadingForm"
      ref="EditTransmitterForm"
      @submit.native.prevent
    )
      el-row(:gutter="24")
        el-col(:span="12")
          el-form-item(
            prop="role_id"
            :label="$t('transmitters.actions.select_field')"
          )
            el-select.w-100(
              :selected="selected"
              v-model='form.field_id'
              filterable
              allow-create
              :placeholder="$t('transmitters.actions.field')"
            )
              el-option(
                v-for='field in fields'
                :key='field.id'
                :label='`${field.site_name}: ${field.name}`'
                :value='field.id'
              )
        el-col(:span="4")
          el-form-item(
            prop="address_lat"
            :label="$t('transmitters.actions.latitude')"
          )
            el-input(
              v-model.number="form.latitude"
              name="address_lat"
              type="number"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_long"
            :label="$t('transmitters.actions.longitude')"
          )
            el-input(
              v-model.number="form.longitude"
              name="address_long"
              type="number"
            )
    el-row.text-center
      el-button.dark-green-btn.mt-5.pop-up-btn(
        @click="handleEditTransmitter('EditTransmitterForm')"
        :loading="loadingSubmitBtn"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "@components/_shared/GoogleMaps";
import _ from "lodash";

export default {
  name: "EditTransmitter",

  components: {
    GoogleMaps,
  },

  data() {
    return {
      form: {
        field_id: null,
        latitude: null,
        longitude: null,
      },
      location: null,
      loadingForm: false,
      loadingSubmitBtn: false,

      selected: '',
      sites: [],
      fields: [],
      isLoading: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["coordinates"]),

    ...mapState("transmitter", ["transmitter"]),

  },

  watch: {
    transmitter() {
      this.handleGetFormData()
      this.updateMapPinFromDockCoordinates();
    },

    'form.latitude': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setMapCoordinates({ lat: newValue, lng: Number(this.form.longitude) });
          this.isLoading = true;
        }
      }
    },

    'form.longitude': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setMapCoordinates({ lat: Number(this.form.latitude), lng: newValue });
          this.isLoading = true;
        }
      }
    },
  },

  async created() {
    this.location = { 
      latitude: Number(this.transmitter.latitude), 
      longitude: Number(this.transmitter.longitude)
    };
    this.loadingForm = true
    await this.handleGetFields();
    this.handleGetFormData()
    this.updateMapPinFromDockCoordinates();

    if (!(this.form.longitude && this.form.latitude)) {
      this.form.latitude = this.coordinates.lat;
      this.form.longitude = this.coordinates.lng;
    }
    this.loadingForm = false
    this.isLoading = true;
  },

  methods: {
    ...mapMutations("dialog", ["hideEditTransmitterDialog"]),

    ...mapMutations("transmitter", ["setShouldFetchTransmitters"]),

    ...mapMutations("map", ["setMapCoordinates"]),

    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapActions("field", ["getFields"]),

    ...mapActions("transmitter", ["updateTransmitter"]),

    async handleGetFields() {
      const fields = await this.getFields("?page_size=1000");
      this.fields = fields.data;
    },

    handleGetFormData() {
      this.form = { ...this.transmitter };
      if(this.transmitter.field)
        this.form.field_id = this.transmitter.field.id
    },

    // async handleSelectAddress(item) {
    //   this.selectedPlaceID = item.place_id;
    //   const placeDetails = await this.getPlaceByID(item.place_id);

    //   let coordinates = {
    //     lat: placeDetails.lat,
    //     lng: placeDetails.lng,
    //   };

    //   await this.setMapCoordinates(coordinates);
    // },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
            address,
            autocompleteCallback
        ) {
          this.handleGetPlaceData(address, autocompleteCallback);
        },
        300),

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      this.form.latitude = markerPosition.lat;
      this.form.longitude = markerPosition.lng;
    },

    updateMapPinFromDockCoordinates() {
      this.setMapCoordinates({
        lat: Number(this.form.latitude),
        lng: Number(this.form.longitude),
      });
    },

    handleEditTransmitter(formName) {
      this.loadingSubmitBtn = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.updateTransmitter(params)
              .then(() => {
                this.hideEditTransmitterDialog();
                this.setShouldFetchTransmitters(true);
              })
              .finally(() => {
                this.loadingSubmitBtn = false;
              });
        } else {
          this.loadingSubmitBtn = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>