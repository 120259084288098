<template lang="pug">
  .navbar
      el-menu.el-menu-side-nav-wrapper(
        :router="false"
        :default-active="activeLink"
        :collapse-transition="false"
        mode="vertical"
        v-if="computeSideBar"
        ref="sidebar"
      )
        .nav_wrapper
          img.logo-wrapper(src="@src/assets/navbar/logo1.png")
          img.logo-words-wrapper(src="@src/assets/navbar/logow.png")
          .items_wrapper
            NavOverviewItem
            NavDetailsItem
            NavNotificationsItem
          .field-filter(style="margin-top: 15px")
            FilterFieldDropdown(v-model="selectedFieldId")
          .profile_wrapper
            NavSettingsItem
            .logout(@click="handleLogout") {{ $t('auth.logout') }}
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
// import { sideMenuMixins } from "@src/mixins/sideMenu";

import NavOverviewItem from "@components/horizontalNavBar/NavOverviewItem";
import NavDetailsItem from "@components/horizontalNavBar/NavDetailsItem";
import NavNotificationsItem from "@components/horizontalNavBar/NavNotificationsItem";

import NavSettingsItem from "@components/horizontalNavBar/NavSettingsItem";

import FilterFieldDropdown from "@components/_shared/FilterFieldDropdown";

export default {
  mixins: [general],

  components: {
    NavOverviewItem,
    NavDetailsItem,
    NavNotificationsItem,
    NavSettingsItem,
    FilterFieldDropdown,

  },

  data() {
    return {
      selectedFieldId: null,
      isMenuOpen: true,
      activeLink: this.$route.path,
      dynamicViewsConstants,
    };
  },


  // created() {
  //   localStorage.setItem("isMenuOpen", this.isMenuOpen);
  // },

  computed: {
    ...mapState("auth", ["user"]),

    computeSideBar() {
      return (
        this.user &&
        this.activeLink !== "/set-password" &&
        this.activeLink !== "/change_email"
      );
    },
  },

  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },

    async selectedFieldId(){
      await this.handleSelectedField();
    }
  },

  methods: {
    ...mapMutations("field", ["setSelectedField", "setSelectedFieldInfoSettings"]),
    ...mapActions({
      logout: "auth/logout",
    }),
    ...mapActions("field", ["getSelectedField"]),

    handleLogout() {
      this.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },

    // handleToggleMenuOpen(isMenuOpen) {
    //   this.isMenuOpen = isMenuOpen;
    //   // this.toggleSideMenu(isMenuOpen);

    //   localStorage.setItem("isMenuOpen", this.isMenuOpen);
    // },

    async handleSelectedField(){
      const response = await this.getSelectedField(this.selectedFieldId);
      this.setSelectedField(response)
      if(response.field_info_settings)
        this.setSelectedFieldInfoSettings(response.field_info_settings)
    }
  }
};
</script>

<style lang="scss">
/* sub menu with link */
.sub-menu-link {
  width: 100%;
  text-decoration: none;
  display: inline-block;
}
.footer {
    position:absolute;
    bottom: 10%;
    right: 15%;
    font-size: 10px;
    cursor: pointer;
}

// .logout {
//   color: #fff !important;
//   margin-top: 6%;
//     // position:absolute;
//     // bottom: 10%;
//     // right: 15%;
//     // font-size: 10px;
//     // cursor: pointer;
// }

// style manually overwriten
// .sidebar{
//   min-width: 15% !important;
// }
</style>
