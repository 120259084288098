<template lang="pug">
  .edit-site
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'EditSite'"
    )
    el-form(
      :model="form"
      v-loading="loadingForm"
      ref="EditSiteForm"
      :rules="addSiteRules"
      @submit.native.prevent
    )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="name"
            :label="$t('sites.label.name')"
          )
            el-input(
              v-model.number="form.name"
              name="name"
            )
        el-col.address(:span="8")
          el-form-item(
            prop="address"
            :label="$t('sites.label.address')"
          )
            el-autocomplete(
              ref="autocomplete"
              v-model="form.address"
              name="address"
              :trigger-on-focus="false"
              @select="handleSelectAddress"
              :fetch-suggestions="addressSearchResultsDebounce"
            )
    el-row.text-center
      el-button.dark-green-btn.mt-5.pop-up-btn(
        @click="handleEditSite('EditSiteForm')"
        :loading="loadingSubmitBtn"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "@components/_shared/GoogleMaps";
import { customValidations } from "@utils/customValidators.js";
import { SitesViewValidations } from "@utils/formValidations/sitesViewValidations";
import _ from "lodash";
import Vue from "vue";

export default {
  name: "EditSite",
  mixins: [SitesViewValidations, customValidations],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      form: {
        name: null,
        address: null,
        address_lat: null,
        address_long: null,
      },
      loadingForm: false,
      loadingSubmitBtn: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["coordinates"]),

    ...mapState("site", ["site"]),

  },

  watch: {
    site() {
      this.handleGetFormData()
      this.updateMapFromAddress();
    },
  },

  async created() {
    this.loadingForm = true
    this.handleGetFormData()
    this.updateMapFromAddress();
    if (!(this.form.address_long && this.form.address_lat)) {
      this.form.address_lat = this.coordinates.lat;
      this.form.address_long = this.coordinates.lng;
    }
    this.loadingForm = false
  },

  methods: {
    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapActions("site", ["updateSite"]),

    ...mapMutations("dialog", ["hideEditSiteDialog"]),

    ...mapMutations("site", ["setShouldFetchSites"]),

    ...mapMutations("map", ["setMapCoordinates"]),

    handleGetFormData() {
      this.form = { ...this.site };
    },

    async handleSelectAddress(item) {
      this.selectedPlaceID = item.place_id;
      const placeDetails = await this.getPlaceByID(item.place_id);

      let coordinates = {
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      };

      await this.setMapCoordinates(coordinates);
    },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
            address,
            autocompleteCallback
        ) {
          this.handleGetPlaceData(address, autocompleteCallback);
        },
        300),

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      this.form.address = address;
      this.form.address_lat = markerPosition.lat;
      this.form.address_long = markerPosition.lng;

      this.$refs.autocomplete.focus();
    },

    updateMapFromAddress() {
      this.setMapCoordinates({
        lat: Number(this.form.address_lat),
        lng: Number(this.form.address_long),
      });
    },

    handleEditSite(formName) {
      this.loadingSubmitBtn = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.updateSite(params)
              .then(() => {
                this.hideEditSiteDialog();
                this.setShouldFetchSites(true);
              })
              .finally(() => {
                this.loadingSubmitBtn = false;
              });
        } else {
          this.loadingSubmitBtn = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>