import { dynamicViewsConstants } from "@src/constants";
import SettingsView from "@views/SettingsView";
import ForgotPasswordView from "@views/auth/ForgotPasswordView";
import LoginView from "@views/auth/LoginView";
import UsersView from "@views/users/UsersView";
import AddUserView from "@views/users/AddUserView";
import ChangePasswordView from "@views/auth/ChangePasswordView";
import ChangeEmailView from "@views/auth/ChangeEmailView";
import EditUserView from "@views/users/EditUserView";
import UserView from "@views/users/UserView";
import SitesView from "@views/SitesView";
import FieldsView from "@views/FieldsView";
import TransmittersView from "@views/TransmittersView";
import ManageSensorsView from "@views/ManageSensorsView";

import ClientOverviewView from "@views/client/OverviewView";
import ClientDetailsView from "@views/client/DetailsView";
import ClientNotificationsView from "@views/client/NotificationsView";

export default [
  {
    path: "/",
    redirect: { name: "settings" },
    requiresAuth: false,
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/sites",
    name: "sites",
    component: SitesView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/overview",
    name: "client_overview",
    component: ClientOverviewView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/details",
    name: "client_details",
    component: ClientDetailsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/notifications",
    name: "client_notifications",
    component: ClientNotificationsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/fields",
    name: "fields",
    component: FieldsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/transmitters",
    name: "transmitters",
    component: TransmittersView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/transmitters/:id/manage_sensors",
    name: "manage_sensors",
    component: ManageSensorsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/users/add_user",
    name: "add_user",
    component: AddUserView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/users/edit_user/:id",
    name: "user_edit",
    component: EditUserView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/users/:id",
    name: "user_view",
    component: UserView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  // {
  //   path: "/forgot_password",
  //   name: "forgot_password",
  //   component: ForgotPasswordView,
  //   meta: {
  //     requiresAuth: false,
  //     requiresSuperAdmin: false,
  //   },
  // },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/change_password",
    name: "change_password",
    component: ChangePasswordView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/change_email",
    name: "change_email",
    component: ChangeEmailView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  }
];
