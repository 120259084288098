<template lang="pug">
  .chart
    //- Statistic.pt-2.pb-3.pl-1.pr-1(
    //-   :name="statisticTitle"
    //-   :value="averageEarningsStatistic"
    //-   :fullWidth="false"
    //-   :isMoneyStatistic="true"
    //-   :justifyContent="'left'"
    //- )
    apexchart(
      type="bar"
      height="380"
      :options="chartOptions"
      :series="chartSensorInfos"
      :key="chartComponentKey"
    )
</template>

<script>
import { chartOptions } from "@utils/generalUtils";
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import Statistic from "@components/_shared/Statistic";

export default {
  name: "Chart",

  props: {
    chartData: {
      type: Object,
      default: {},
    },

    chartType: {
      type: String,
    },

    statisticTitle: {
      type: String,
    }
  },

  components: {
    apexchart: VueApexCharts,
    Statistic,
  },

  watch: {
    chartData() {
      this.mapInfosForChart();
    },
  },

  data() {
    return {
      chartSensorInfos: [
        {
          name: this.$t("sensors.chart.humidity"),
          data: [],
        },
        {
          name: this.$t("sensors.chart.temperature"),
          data: [],
        },
        {
          name: this.$t("sensors.chart.conductivity"),
          data: [],
        },
                {
          name: this.$t("sensors.chart.ph"),
          data: [],
        },
        {
          name: this.$t("sensors.chart.nitrogen"),
          data: [],
        },
        {
          name: this.$t("sensors.chart.phosphorus"),
          data: [],
        },
                {
          name: this.$t("sensors.chart.potassium"),
          data: [],
        }
      ],

      averageEarningsStatistic: 0,
      chartComponentKey: 0,
      chartOptions: chartOptions,
    };
  },

  async created() {
    this.mapInfosForChart();
  },

  methods: {
    mapInfosForChart() {
      if (this.chartType === "sensorNPKInfos") {
        this.computeSensorNPKInfos();
      } else if (this.chartType === "sensorTHCNPKInfos") {
        this.computeSensorTHCNPKInfos();
      } else if (this.chartType === "sensorTHPHNPKInfos") {
        this.computeSensorTHPHNPKInfos();
      } else if (this.chartType === "sensorPHInfos") {
        this.computeSensorPHInfos();
      } else if (this.chartType === "sensorTHInfos") {
        this.computeSensorTHInfos();
      } else if (this.chartType === "sensorTHCInfos") {
        this.computeSensorTHCInfos();
      } else if (this.chartType === "sensorTHCPHInfos") {
        this.computeSensorTHCPHInfos();
      } else if (this.chartType === "sensorTHPHInfos") {
        this.computeSensorTHPHInfos();
      } else if (this.chartType === "sensorTHCPHNPKInfos") {
        this.computeSensorTHCPHNPKInfos();
      }
    },

    computeSensorNPKInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          console.log(info)
          this.chartSensorInfos[4].data.push(info.nitrogen);
          this.chartSensorInfos[5].data.push(info.phosphorus);
          this.chartSensorInfos[6].data.push(info.potassium);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHCNPKInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);
          this.chartSensorInfos[2].data.push(info.conductivity);
          this.chartSensorInfos[4].data.push(info.nitrogen);
          this.chartSensorInfos[5].data.push(info.phosphorus);
          this.chartSensorInfos[6].data.push(info.potassium);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHPHNPKInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);
          this.chartSensorInfos[3].data.push(info.ph);
          this.chartSensorInfos[4].data.push(info.nitrogen);
          this.chartSensorInfos[5].data.push(info.phosphorus);
          this.chartSensorInfos[6].data.push(info.potassium);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorPHInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[3].data.push(info.ph);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHCInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);
          this.chartSensorInfos[2].data.push(info.conductivity);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHCPHInfos(){
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);
          this.chartSensorInfos[2].data.push(info.conductivity);
          this.chartSensorInfos[3].data.push(info.ph);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHPHInfos(){
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);
          this.chartSensorInfos[3].data.push(info.ph);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

    computeSensorTHCPHNPKInfos() {
      this.chartComponentKey += 1;

      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((info) => {
          mappedDays.push(info.date);
          this.chartSensorInfos[0].data.push(info.humidity);
          this.chartSensorInfos[1].data.push(info.temperature);
          this.chartSensorInfos[2].data.push(info.conductivity);
          this.chartSensorInfos[3].data.push(info.ph);
          this.chartSensorInfos[4].data.push(info.nitrogen);
          this.chartSensorInfos[5].data.push(info.phosphorus);
          this.chartSensorInfos[6].data.push(info.potassium);

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

            },
          };
        });
      }
    },

  },
};
</script>
