import axios from "axios";
import Jsona from "jsona";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  field: null,
  selectedField: null,
  selectedFieldInfoSettings: null,
  shouldFetchFields: false
};

export const actions = {
  async getFields({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/fields${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getFieldsList({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/fields${payload}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getField({ commit }, id) {
    try {
      const response = await axios.get(`/v1/fields/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getSelectedField({ commit }, id) {
    try {
      const response = await axios.get(`/v1/fields/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addField({ commit }, payload) {
    try {
      await axios.post("/v1/fields", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateField({ commit }, payload) {
    try {
      await axios.patch(`/v1/fields/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_updated"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteField({ commit }, payload) {
    try {
      console.log(payload)
      await axios.delete(`/v1/fields/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getFieldInfoSettings({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/field_info_settings${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setField(state, field) {
    state.field = field;
  },

  setSelectedField(state, selectedField) {
    state.selectedField = selectedField;
  },

  setSelectedFieldInfoSettings(state, selectedFieldInfoSettings){
    state.selectedFieldInfoSettings = selectedFieldInfoSettings;
  },

  setShouldFetchFields(state, value) {
    state.shouldFetchFields = value;
  }
};
