<template lang="pug">
  el-menu-item(index='/notifications')
    router-link.sub-menu-link(to="/notifications")
      .img-wrapper
        img(style="height: 80%; width: 80%" src="@src/assets/navbar/notifications.svg")
      span  {{ $t('navbar.notifications') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "NavNotificationsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
