import { render, staticRenderFns } from "./PasswordChangeForm.vue?vue&type=template&id=32006306&scoped=true&lang=pug"
import script from "./PasswordChangeForm.vue?vue&type=script&lang=js"
export * from "./PasswordChangeForm.vue?vue&type=script&lang=js"
import style0 from "./PasswordChangeForm.vue?vue&type=style&index=0&id=32006306&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32006306",
  null
  
)

export default component.exports