<template lang="pug">
  .sidebar
    el-menu.el-menu-wrapper.scroll-el-menu(
      :class="{'closed-menu': !isMenuOpen}"
      :router="false"
      :default-active="activeLink"
      :collapse-transition="false"
      mode="vertical"
      v-if="computeSideBar"
      ref="sidebar"
    )
      NavProfileComponent(
        :isMenuOpen="isMenuOpen"
        @toggleMenuOpen="handleToggleMenuOpen"
      )
      div.menu-list
        ManagementItem
        UsersItem
        //- SitesItem
        //- FieldsItem
        SettingsItem
        
        //- AdminSettingsItem

      //- h3.mt-2.text-white.w-fit-content.footer(@click="handleLogout") {{ $t('auth.logout') }}
</template>

<script>
import { mapState, mapActions } from "vuex";
import NavProfileComponent from "../_shared/NavProfileComponent.vue";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import { sideMenuMixins } from "@src/mixins/sideMenu";

import UsersItem from "@components/sidebar/UsersItem";
import SitesItem from "@components/sidebar/SitesItem";
import FieldsItem from "@components/sidebar/FieldsItem";

import SettingsItem from "@components/sidebar/SettingsItem";

import ManagementItem from "@components/sidebar/ManagementItem";


export default {
  mixins: [general, sideMenuMixins],

  components: {

    UsersItem,
    SitesItem,
    FieldsItem,
    SettingsItem,
    ManagementItem,
    NavProfileComponent,

  },

  data() {
    return {
      isMenuOpen: true,
      activeLink: this.$route.path,
      dynamicViewsConstants,
    };
  },

  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),

    handleLogout() {
      this.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },

    handleToggleMenuOpen(isMenuOpen) {
      this.isMenuOpen = isMenuOpen;
      this.toggleSideMenu(isMenuOpen);

      localStorage.setItem("isMenuOpen", this.isMenuOpen);
    },
  },

  created() {
    localStorage.setItem("isMenuOpen", this.isMenuOpen);
  },

  computed: {
    ...mapState("auth", ["user"]),

    computeSideBar() {
      return (
        this.user &&
        this.activeLink !== "/set-password" &&
        this.activeLink !== "/change_email"
      );
    },
  },

  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },
  },
};
</script>

<style lang="scss">
/* sub menu with link */
.sub-menu-link {
  width: 100%;
  text-decoration: none;
  display: inline-block;
}
.footer {
    position:absolute;
    bottom: 10%;
    right: 15%;
    font-size: 10px;
    cursor: pointer;
}

// style manually overwriten
// .sidebar{
//   min-width: 15% !important;
// }
</style>
