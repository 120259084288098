import messageUtils from "@utils/messageUtils";
import axios from "axios";

import Jsona from "jsona";
import i18n from "@i18n";
const dataFormatter = new Jsona();

export const state = {
  transmitter: null,
  shouldFetchTransmitters: false,
};

export const actions = {
  async getTransmitters({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/sensor_transmitters${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTransmitter({ commit }, id) {
    try {
      const response = await axios.get(`/v1/sensor_transmitters/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateTransmitter({ commit }, payload) {
    try {
      return axios.patch(`/v1/sensor_transmitters/${payload.id}`, { ...payload });
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addSoilSensor({ commit }, payload) {
    try {
      return axios.post(`/v1/sensor_transmitters/${payload.id}/add_sensor`);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  // async getTicketTypeStatuses({ commit }, payload) {
  //   try {
  //     const response = await axios.get(`v1/ticket_type_statuses${payload}`);
  //     return {
  //       data: dataFormatter.deserialize(response.data),
  //     };
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

  // async getTicketByTypeStatuses({ commit }, payload) {
  //   try {
  //     const response = await axios.get(`v1/sensor_transmitters?by_ticket_type=${payload}`);
  //     return {
  //       data: dataFormatter.deserialize(response.data),
  //     };
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },



  // async assignRoles({ commit }, payload) {
  //   try {
  //     await axios.post(`/v1/ticket_type_statuses/${payload.id}/assign_roles`, {
  //       ...payload,
  //     });
  //     return messageUtils.showSuccess(i18n.t("common.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

  // async assignUsers({ commit }, payload) {
  //   try {
  //     await axios.post(`/v1/ticket_type_statuses/${payload.id}/assign_users`, {
  //       ...payload,
  //     });
  //     return messageUtils.showSuccess(i18n.t("common.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },
};

export const mutations = {
  setTransmitter(state, transmitter) {
    state.transmitter = transmitter;
  },

  setShouldFetchTransmitters(state, value) {
    state.shouldFetchTransmitters = value;
  },
  
};
