import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress/nprogress";
import routes from "./routes";
import store from "@/src/state/store";
import { showItemDynamically } from "@/src/services/dynamicViews/general";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "hash",
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  NProgress.start();

  const loggedIn = !!$cookies.get("access_token");
  const access_token = $cookies.get("access_token");
  const refresh_token = $cookies.get("refresh_token");

  if (loggedIn && !store.state.auth.user) {
    try {
      await store.dispatch("auth/sessionUser", { access_token, refresh_token });
    } catch (e) {
      await store.dispatch("auth/refreshToken");
      await store.dispatch("auth/sessionUser", {
        access_token: store.state.auth.access_token,
        refresh_token: store.state.auth.refresh_token,
      });
    }
  }

  // redirect from login/forgot-password if user is authenticated already
  if (
    loggedIn &&
    (routeTo.name === "login" ||
      routeTo.name === "forgot_password" ||
      routeTo.name === "change_password")
  ) {
    next("/settings");
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.requiresAuth);

  if (authRequired) {
    if (loggedIn) {
      console.log(store.state.auth);
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

router.afterEach(NProgress.done);

/**
 * handles the show/hide components & sidebar items based on the data from the API
 */
function handleGuardsDynamicViews(to, next) {
  // handle edge case views
  if (to.meta.resource_name !== undefined) {
    switch (to.name) {
      case "docking_stations_map":
        return proceedToView(to, ["show_statistics"])
          ? next()
          : next("/settings");

      case "user_edit":
        return proceedToView(to, ["update", "show"])
          ? next()
          : next("/settings");

      case "user_view":
        return proceedToView(to, ["show"]) ? next() : next("/settings");

      case "role":
      case "role_edit":
        return proceedToView(to, ["show"]) ? next() : next("/settings");

      case "inventory":
        return proceedToView(to, ["create"]) ? next() : next("/settings");
    }
    // normal views
    proceedToView(to, ["index"]) ? next() : next("/settings");
  } else {
    return next();
  }
}

function proceedToView(to, requiredPermissions) {
  return showItemDynamically(store.state.auth.user, to.meta.resource_name, [
    ...requiredPermissions,
  ]);
}

export default router;
