<template lang="pug">
  el-menu-item(index='/settings')
    //- .img-wrapper
      //- img(src="@src/assets/sidebar/settings.svg")
    router-link.sub-menu-link(to="/settings")
      span.text-underline.cursor-pointer {{ user.first_name + " " + user.last_name }}
      //- span  {{ $t('sidebar.settings') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "NavSettingsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>