<template lang="pug">
  .cost-center-role-dropdowns
    el-collapse-item(
      title="Role & Permission"
      :name="collapse"
    )
      template(slot="title")
        h3 {{ $t('cost_centers.roles_and_permissions') }}
        h4.ml-2 {{ form.cost_center_id ? costCenterName + ' (' + upperCaseFirstLetters(roleName) + ') ' : ''}}
      .role-and-permissions
      el-form(
        :model="form"
        :rules="rolesAndPermissionsRules"
        ref="RolesAndPermissionsForm"
        @submit.native.prevent
      )
        el-row(:gutter="25")
          el-col(:span="8")
            el-form-item(
              prop="cost_center_id"
              :label="$t('common.cost_center')"
            )
              el-select.w-100(
                v-model='form.cost_center_id'
                filterable
                :placeholder="$t('placeholder.select_cost_center')"
                @change="handleOnDropdownSelection($event, collapse, 'costCenter')"
              )
                el-option(
                  v-for='cost_center in loadedCostCenters'
                  :key='cost_center.id'
                  :label='cost_center.name'
                  :value='cost_center.id'
                  :disabled="cost_center.disabled"
                )
          el-col(:span="8")
            el-form-item(
              prop="role_ids"
              :label="$t('label.role')"
            )
              el-select.w-100(
                v-model='form.role_ids'
                :disabled='superAdmin ? superAdmin : isSelectedCostCenterDisabled'
                filterable
                multiple
                :placeholder="$t('common.select_role')"
                @change="handleOnDropdownSelection($event, collapse, 'role')"
              )
                el-option(
                  v-for='role in roles'
                  :key='role.id'
                  :label='upperCaseFirstLetters(role.name)'
                  :value='role.id'
                )
        RolePermissionsList(
          v-model="checkList"
          v-if="!superAdmin"
          :isEdit="isEdit"
          :newRoleSelect="newRoleSelect"
          :role="{permissions: loadedChecklists}"
          :collapse="collapse"
          :key="rolePermissionsListKey"
        )
    .d-flex.align-items-center.justify-content-flex-end
      el-button.mb-4.dark-green-btn.pop-up-btn.delete-btn(
        v-if="collapses.length > 1"
        @click="handleRemoveCollapse(index)"
      ) {{ $t('common.remove') }}
</template>

<script>
import _ from "lodash";
import { usersViewValidations } from "@/src/utils/formValidations/usersViewValidations";
import { customValidations } from "@utils/customValidators";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { upperCaseFirstLetters } from "@utils/generalUtils";
import RolePermissionsList from "@components/_shared/RolePermissionsList";
import { roles } from '@utils/roles'

export default {
  name: "CollapseComponent",
  components: { RolePermissionsList },
  mixins: [usersViewValidations, customValidations],

  props: {
    isEdit: {
      type: Boolean,
      required: false
    },

    collapses: {
      type: Array,
      default: 0,
    },

    collapse: {
      type: Number,
      default: null,
    },

    customerCollapse: {
      type: Number,
      default: null,
    },

    index: {
      type: Number,
      default: 0,
    },

    superAdmin: {
      type: Boolean,
      required: false
    },

    userDetails: {
      type: Object,
      required: false
    }
  },

  computed: {
    ...mapState("user", ["loadedCostCenters"]),
    ...mapGetters("user", ["getCollapsesData"]),
    ...mapState("customer", ["customerLoadedCostCenters"]),
    ...mapGetters("customer", ["getCustomerCollapsesData"]),

    roleName(){
      let roleName = this.roles.filter(role => this.form.role_ids
          .includes(role.id))
          .map(role => role.name);

      return roleName.toString();
    },

    costCenterName(){
      let costCenterName = this.loadedCostCenters.filter(costCenterName => this.form.cost_center_id
          .includes(costCenterName.id))
          .map(costCenterName => costCenterName.name);

      return costCenterName.toString();
    },

    isSelectedCostCenterDisabled() {
      // if the role administrator or super administrator is selected then
      // disable and clear the cost center field
      let rolesNamesFromIds = this.roles.filter(role => this.form.role_ids
          .includes(role.id))
          .map(role => role.name)

      return rolesNamesFromIds.some((val) => [roles.SUPER_ADMINISTRATOR].includes(val))
    }
  },

  watch: {
    getCollapsesData() {
      this.getCostCenterData();
    },

    getCustomerCollapsesData(){
      this.getCustomerCostCenterData();
    },

    checkList() {
      this.handleUpdateCollapseData();
      this.handleUpdateCustomerCollapseData();
      this.handleUpdateUserCostCenterPermissions(this.checkList);
      this.handleUpdateCustomerCostCenterPermissions(this.checkList);
    },
  },

  data() {
    return {
      checkList: [],
      prevDisabledCostCenterId: null,
      roles: [],
      loadedChecklists: [],
      mappedRestrictedOrAuthorizedPermissions: [],
      form: {
        cost_center_id: null,
        role_ids: null,
      },
      loading: false,
      newRoleSelect: false,
      rolePermissionsListKey: 0,
      upperCaseFirstLetters,
    };
  },

  async created() {
    await this.getCostCenterData()
    await this.getCustomerCostCenterData();
    await this.handleGetRoles();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("role", [
      "getRoles",
      "getRole",
      "getAllPermissionsByRoleIds",
    ]),
    ...mapMutations("user", [
      "setCollapsesData",
      "removeCollapseData",
      "setDisabledCostCenter",
    ]),
    ...mapMutations("customer", [
      "setCollapsesDataCustomer",
      "removeCollapseData",
      "setDisabledCostCenter",
    ]),

    async getCostCenterData() {
      let foundCollapseDataIndex = this.getCollapsesData.find(
          (data) => data.collapse === this.collapse
      );
      let foundCollapseData = this.getCollapsesData[foundCollapseDataIndex];

      if (foundCollapseData) {
        this.form.cost_center_id = foundCollapseData.cost_center_id;
        this.form.role_ids = foundCollapseData.role_ids;
        this.checkList = _.clone(foundCollapseData.alreadyChecked); // vuex mutation error fix
        this.loadedChecklists = foundCollapseData.loadedChecklists;
        this.prevDisabledCostCenterId = foundCollapseData.cost_center_id
      }
    },

    async getCustomerCostCenterData(){
      let foundCollapseDataIndex = this.getCustomerCollapsesData.find(
          (data) => data.collapse === this.customerCollapse
      );
      let foundCollapseData = this.getCustomerCollapsesData[foundCollapseDataIndex];

      if (foundCollapseData) {
        this.form.cost_center_id = foundCollapseData.cost_center_id;
        this.form.role_ids = foundCollapseData.role_ids;
        this.checkList = _.clone(foundCollapseData.alreadyChecked); // vuex mutation error fix
        this.loadedChecklists = foundCollapseData.loadedChecklists;
        this.prevDisabledCostCenterId = foundCollapseData.cost_center_id
      }
    },

    async handleRemoveCollapse(index) {
      if (this.collapses.length > 1) {
        this.$emit("removeCollapse", index);
        this.removeCollapseData(this.collapse); // remove this selected collapse
        this.setDisabledCostCenter({
          selectedCostCenterId: this.form.cost_center_id,
          disabled: false,
        });
      }
    },

    async handleGetRoles() {
      if(this.superAdmin)
        this.roles= this.userDetails.roles;

      this.roles = await this.getRoles();
    },

    async loadCheckedPermissions(roleIds) {
      this.checkList = [];
      this.loadedChecklists = [];
      let params = `?by_role_ids=${roleIds.map((id) => id)}`;

      if (roleIds.length > 0) {
        this.loadedChecklists = await this.getAllPermissionsByRoleIds(params);
        this.rolePermissionsListKey += 1
      }
    },

    handleUpdateUserCostCenterPermissions(checkList) {
      if (this.loadedChecklists) {
        const removedPermissions = _.difference(
            this.loadedChecklists.map((perm) => perm.id),
            checkList
        );
        const addedPermissions = _.difference(
            checkList,
            this.loadedChecklists.map((perm) => perm.id)
        );
        this.mappedRestrictedOrAuthorizedPermissions = [];

        const computeRestrictedOrAuthorizedPermission = (grant_type, permId) => {
          return {
            cost_center_id: this.form.cost_center_id,
            permission_id: permId,
            grant_type,
            collapse: this.collapse,
          };
        };

        // map and add to user_cost_center_permissions
        removedPermissions.forEach((removedPermId) => {
          this.mappedRestrictedOrAuthorizedPermissions.push(
              computeRestrictedOrAuthorizedPermission("restrict", removedPermId)
          );
        });

        addedPermissions.forEach((addedPermId) => {
          this.mappedRestrictedOrAuthorizedPermissions.push(
              computeRestrictedOrAuthorizedPermission("authorize", addedPermId)
          );
        });

        this.setCollapsesData({
          collapse: this.collapse,
          cost_center_id: this.isSelectedCostCenterDisabled ? null : this.form.cost_center_id,
          role_ids: this.form.role_ids,
          alreadyChecked: this.checkList,
          loadedChecklists: this.loadedChecklists,
          permissions: this.mappedRestrictedOrAuthorizedPermissions,
        });
      }
    },

    handleUpdateCustomerCostCenterPermissions(checkList) {
      if (this.loadedChecklists) {
        const removedPermissions = _.difference(
            this.loadedChecklists.map((perm) => perm.id),
            checkList
        );
        const addedPermissions = _.difference(
            checkList,
            this.loadedChecklists.map((perm) => perm.id)
        );
        this.mappedRestrictedOrAuthorizedPermissions = [];

        const computeRestrictedOrAuthorizedPermission = (grant_type, permId) => {
          return {
            cost_center_id: this.form.cost_center_id,
            permission_id: permId,
            grant_type,
            collapse: this.customerCollapse,
          };
        };

        // map and add to user_cost_center_permissions
        removedPermissions.forEach((removedPermId) => {
          this.mappedRestrictedOrAuthorizedPermissions.push(
              computeRestrictedOrAuthorizedPermission("restrict", removedPermId)
          );
        });

        addedPermissions.forEach((addedPermId) => {
          this.mappedRestrictedOrAuthorizedPermissions.push(
              computeRestrictedOrAuthorizedPermission("authorize", addedPermId)
          );
        });

        this.setCollapsesDataCustomer({
          collapse: this.customerCollapse,
          cost_center_id: this.isSelectedCostCenterDisabled ? null : this.form.cost_center_id,
          role_ids: this.form.role_ids,
          alreadyChecked: this.checkList,
          loadedChecklists: this.loadedChecklists,
          permissions: this.mappedRestrictedOrAuthorizedPermissions,
        });
      }
    },

    async handleOnDropdownSelection(selectedCostCenterId, collapse, dropdown) {
      this.handleUpdateCollapseData();
      this.handleUpdateCustomerCollapseData();
      // load role from dropdown
      if (dropdown === "role") {
        if (collapse !== undefined) {
          this.newRoleSelect = true
          await this.loadCheckedPermissions(this.form.role_ids);
        }
      }

      if (dropdown === "costCenter") {
        this.handleUpdateUserCostCenterPermissions(this.checkList);
        this.handleUpdateCustomerCostCenterPermissions(this.checkList);
        if (
            this.prevDisabledCostCenterId &&
            this.prevDisabledCostCenterId !== selectedCostCenterId
        ) {
          this.setDisabledCostCenter({
            selectedCostCenterId: this.prevDisabledCostCenterId,
            disabled: false,
          });
        }
        this.prevDisabledCostCenterId = selectedCostCenterId;
        this.setDisabledCostCenter({
          selectedCostCenterId,
          disabled: true,
        });
      }
    },

    handleUpdateCollapseData() {
      this.setCollapsesData({
        collapse: this.collapse,
        cost_center_id: this.isSelectedCostCenterDisabled ? null : this.form.cost_center_id, // send null if cost center is disabled
        role_ids: this.form.role_ids,
        alreadyChecked: this.checkList,
        loadedChecklists: this.loadedChecklists,
        permissions: [],
      });
    },

    handleUpdateCustomerCollapseData(){
      this.setCollapsesDataCustomer({
        collapse: this.customerCollapse,
        cost_center_id: this.isSelectedCostCenterDisabled ? null : this.form.cost_center_id, // send null if cost center is disabled
        role_ids: this.form.role_ids,
        alreadyChecked: this.checkList,
        loadedChecklists: this.loadedChecklists,
        permissions: [],
      });
    }
  },
};
</script>
