<template lang="pug">
  .charts-wrapper.mb-4(
    v-loading="loadingStatistics"
  )
    Chart(
      :statisticTitle="$t('transactions.average_earnings')"
      :chartData="sensorInfos"
      :chartType="chartType"
    )
</template>

<script>
import Chart from "@components/transactions/Chart";
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "EditTransmitter",

  components: {
    Chart,
  },

  data() {
    return {
      loadingStatistics: false,
      sensorInfos: null,
      chartType: null,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["coordinates"]),

    ...mapState("soilSensor", ["soilSensor"]),

  },

  async created() {
    if(this.soilSensor.sensor_type == '0e'){
      this.chartType = "sensorNPKInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == 'ee') {
      this.chartType = "sensorTHCNPKInfos"
      await this.handleGetTransactionsStatistics();
    }  else if(this.soilSensor.sensor_type == 'de') {
      this.chartType = "sensorTHPHNPKInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == '10') {
      this.chartType = "sensorPHInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == 'c0') {
      this.chartType = "sensorTHInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == 'e0') {
      this.chartType = "sensorTHCInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == 'f0') {
      this.chartType = "sensorTHCPHInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == 'd0') {
      this.chartType = "sensorTHPHInfos"
      await this.handleGetTransactionsStatistics();
    } else if(this.soilSensor.sensor_type == 'fe') {
      this.chartType = "sensorTHCPHNPKInfos"
      await this.handleGetTransactionsStatistics();
    }

  },

  methods: {
    ...mapActions("sensorInfo", ['getSoilSensorInfo']),

    async handleGetTransactionsStatistics() {
      this.loadingStatistics = true;
      const response = await this.getSoilSensorInfo(`?page_size=8&by_soil_sensor_id=${this.soilSensor.id}`)
      if (response.length > 0)
        this.sensorInfos = response.reverse()
      else
        this.sensorInfos = response
      this.loadingStatistics = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>