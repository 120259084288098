<template lang="pug">
  .edit-site
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'EditSensor'"
    )
    el-form(
      :model="form"
      v-loading="loadingForm"
      ref="EditTransmitterForm"
      @submit.native.prevent
    )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="role_id"
            :label="$t('sensors.actions.select_sensor_type')"
          )
            el-select(
              :selected="selected"
              v-model='form.sensor_type'
              filterable
              allow-create
              :placeholder="$t('sensors.actions.sensor')"
            )
              el-option(
                v-for='sensor_type in sensor_types'
                :key='sensor_type.value'
                :label='sensor_type.name'
                :value='sensor_type.value'
              )
        el-col(:span="4")
          el-form-item(
            prop="sensing_radius"
            :label="$t('sensors.actions.sensing_radius')"
          )
            el-input(
              v-model.number="form.sensing_radius"
              name="sensing_radius"
              type="number"
            )
        el-col(:span="4")
          el-form-item(
            prop="altitude"
            :label="$t('sensors.actions.altitude')"
          )
            el-input(
              v-model.number="form.altitude"
              name="altitude"
              type="number"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_lat"
            :label="$t('sensors.actions.latitude')"
          )
            el-input(
              v-model.number="form.latitude"
              name="address_lat"
              type="number"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_long"
            :label="$t('sensors.actions.longitude')"
          )
            el-input(
              v-model.number="form.longitude"
              name="address_long"
              type="number"
            )
    el-row.text-center
      el-button.dark-green-btn.mt-5.pop-up-btn(
        @click="handleEditTransmitter('EditTransmitterForm')"
        :loading="loadingSubmitBtn"
      ) {{ $t('sensors.actions.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "@components/_shared/GoogleMaps";
import _ from "lodash";

export default {
  name: "EditTransmitter",

  components: {
    GoogleMaps,
  },

  data() {
    return {
      form: {
        sensor_type: null,
        sensing_radius: null,
        altitude: null,
        latitude: null,
        longitude: null,
      },
      location: null,
      loadingForm: false,
      loadingSubmitBtn: false,

      selected: '',
      sites: [],
      isLoading: false,
      
      sensor_types: [
        { name: "NPK", value: '0e' },
        { name: "THCNPK", value: 'ee' },
        { name: "THPHNPK", value: 'de' },
        { name: "PH", value: '10' },
        { name: "TH", value: 'c0' },
        { name: "THC", value: 'e0' },
        { name: "THCPH", value: 'f0' },
        { name: "THPH", value: 'd0' },
        { name: "THCPHNPK", value: 'fe' },
      ],
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["coordinates"]),

    ...mapState("soilSensor", ["soilSensor"]),

  },

  watch: {
    soilSensor() {
      this.handleGetFormData()
      this.updateMapPinFromDockCoordinates();
    },

    'form.sensing_radius': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setSensingRadius(this.form.sensing_radius);
          this.isLoading = true;
        }
      }
    },

    'form.latitude': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setMapCoordinates({ lat: newValue, lng: Number(this.form.longitude) });
          this.isLoading = true;
        }
      }
    },

    'form.longitude': {
      handler(newValue, oldValue) {
        if (this.isLoading && oldValue !== newValue) {
          this.isLoading = false;
          this.setMapCoordinates({ lat: Number(this.form.latitude), lng: newValue });
          this.isLoading = true;
        }
      }
    },
  },

  async created() {
    this.location = { 
      latitude: Number(this.soilSensor.latitude), 
      longitude: Number(this.soilSensor.longitude)
    };
    this.loadingForm = true;
    this.handleGetFormData();
    this.updateMapPinFromDockCoordinates();

    if (!(this.form.longitude && this.form.latitude)) {
      this.form.latitude = this.coordinates.lat;
      this.form.longitude = this.coordinates.lng;
    }
    this.loadingForm = false
    this.isLoading = true;
  },

  methods: {
    ...mapMutations("dialog", ["hideEditSensorDialog"]),

    ...mapMutations("soilSensor", ["setShouldFetchSoilSensors"]),

    ...mapMutations("map", ["setMapCoordinates", "setSensingRadius"]),

    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapActions("soilSensor", ["updateSoilSensor"]),


    handleGetFormData() {
      this.form = { ...this.soilSensor };
      if(this.soilSensor.sensor_type)
        this.form.sensor_type = this.soilSensor.sensor_type
    },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
            address,
            autocompleteCallback
        ) {
          this.handleGetPlaceData(address, autocompleteCallback);
        },
        300),

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      this.form.latitude = markerPosition.lat;
      this.form.longitude = markerPosition.lng;
    },

    updateMapPinFromDockCoordinates() {
      this.setMapCoordinates({
        lat: Number(this.form.latitude),
        lng: Number(this.form.longitude),
      });
    },

    handleEditTransmitter(formName) {
      this.loadingSubmitBtn = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };
          console.log(params)

          this.updateSoilSensor(params)
              .then(() => {
                this.hideEditSensorDialog();
                this.setShouldFetchSoilSensors(true);
              })
              .finally(() => {
                this.loadingSubmitBtn = false;
              });
        } else {
          this.loadingSubmitBtn = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>