<template lang="pug">
</template>

<script>

export default {
  name: "NotificationsView",
};
</script>

<style lang="scss" scoped>
@import "@variables";

</style>
