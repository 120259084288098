<template lang="pug">
  .dialog
    .view-password-validation-delete-dialog
      el-dialog(
        :title="dialogData.dialogTitle"
        center
        :visible.sync='isViewPasswordValidationDelete'
        width='567px'
        :before-close='hideViewPasswordValidationDeleteDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        PasswordValidationDelete

    .add-site-dialog
      el-dialog.custom-map-dialog(
        v-if="isAddSiteDialogVisible"
        :title="$t('dialog.add_new_site')"
        center
        :visible.sync='isAddSiteDialogVisible'
        width='940px'
        :before-close='hideAddSiteDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddSite

    .edit-site-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditSiteDialogVisible"
        :title="$t('dialog.edit_site')"
        center
        :visible.sync='isEditSiteDialogVisible'
        width='940px'
        :before-close='hideEditSiteDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditSite

    .add-user-dialog
      el-dialog.custom-map-dialog(
        v-if="isAddUserDialogVisible"
        :title="$t('dialog.add_new_user')"
        center
        :visible.sync='isAddUserDialogVisible'
        width='800px'
        :before-close='hideAddUserDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddUser

    .edit-user-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditUserDialogVisible"
        :title="$t('dialog.edit_user')"
        center
        :visible.sync='isEditUserDialogVisible'
        width='800px'
        :before-close='hideEditUserDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditUser

    .add-field-dialog
      el-dialog.custom-map-dialog(
        v-if="isAddFieldDialogVisible"
        :title="$t('dialog.add_new_field')"
        center
        :visible.sync='isAddFieldDialogVisible'
        width='940px'
        :before-close='hideAddFieldDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddField

    .edit-field-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditFieldInfoSettingsDialogVisible"
        :title="$t('dialog.edit_field')"
        center
        :visible.sync='isEditFieldInfoSettingsDialogVisible'
        width='940px'
        :before-close='hideEditFieldInfoSettingsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditField

    .edit-transmitter-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditTransmitterDialogVisible"
        :title="$t('dialog.edit_transmitter')"
        center
        :visible.sync='isEditTransmitterDialogVisible'
        width='940px'
        :before-close='hideEditTransmitterDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditTransmitter

    .manage-sensors-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditSensorDialogVisible"
        :title="$t('dialog.edit_sensor')"
        center
        :visible.sync='isEditSensorDialogVisible'
        width='940px'
        :before-close='hideEditSensorDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditSensor

    .manage-sensors-dialog
      el-dialog.custom-map-dialog(
        v-if="isViewSensorDialogVisible"
        :title="$t('dialog.view_sensor')"
        center
        :visible.sync='isViewSensorDialogVisible'
        width='1500px'
        :before-close='hideViewSensorDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewSensor

</template>

<script>
import { bus } from "@src/main";
import { mapMutations, mapState } from "vuex";
import AddUser from "./templates/AddUser.vue";
import EditUser from "./templates/EditUser.vue";
import AddSite from "./templates/AddSite.vue";
import EditSite from "./templates/EditSite.vue";
import AddField from "./templates/AddField.vue";
import EditField from "./templates/EditField.vue";
import EditTransmitter from "./templates/EditTransmitter.vue";
import EditSensor from "./templates/EditSensor.vue";
import ViewSensor from "./templates/ViewSensor.vue";

import PasswordValidationDelete from "./templates/PasswordValidationDelete.vue";

export default {
  name: "Dialogs",
  components: {
    AddUser,
    EditUser,
    AddSite,
    EditSite,
    AddField,
    EditField,
    EditTransmitter,
    EditSensor,
    ViewSensor,

    PasswordValidationDelete,

  },

  computed: {
    ...mapState("dialog", [
      "isAddSiteDialogVisible",
      "isEditSiteDialogVisible",
      "isViewUserDialogVisible",
      "isAddNewUserDialogVisible",
      "isViewPasswordValidationDelete",
      "isAddUserDialogVisible",
      "isEditUserDialogVisible",
      "isAddFieldDialogVisible",
      "isEditFieldInfoSettingsDialogVisible",
      "isEditTransmitterDialogVisible",
      "isEditSensorDialogVisible",
      "isViewSensorDialogVisible"
    ]),

    ...mapState("passwordValidationDelete", ["dataToDelete", "dialogData"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", [
      "hideAddSiteDialog",
      "hideEditSiteDialog",
      "hideViewUserDialog",
      "hideAddNewUserDialog",
      "hideEditUserDialog",
      "hideViewPasswordValidationDeleteDialog",
      "hideAddUserDialog",
      "hideEditUserDialog",
      "hideAddFieldDialog",
      "hideEditFieldInfoSettingsDialog",
      "hideEditTransmitterDialog",
      "hideEditSensorDialog",
      "hideViewSensorDialog"
    ]),

    destroyRidesMap() {
      bus.$emit("destroy-map");

      this.hideViewRideDetailsDialog();
    },

    createMap() {
      bus.$emit("create-map");
    },
  },
};
</script>

<style lang="scss">

</style>
