import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  user: null,
  customer: null,
  shouldFetchUsers: false,
  selectedTabRole: null,

  loadedSites: [],
  collapsesData: [],
  allPermissionsGrouped: {},

  newAddUserData: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    user_role_sites: []
  },
};

export const getters = {
  getCollapsesData: (state) => state.collapsesData,
};

export const actions = {

  async addUser({ commit, dispatch }, payload) {
    try {
      await axios.post("/v1/users", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async editUser({ commit, dispatch }, payload) {
    try {
      await axios.patch(`/v1/users/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
  
  async updateProfile({ commit, dispatch }, payload) {
    try {
      await axios.patch("/v1/profiles/update_current", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async changePassword({ commit, dispatch }, payload) {
    try {
      await axios.patch("/v1/profiles/change_password", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async resetUserPassword({ commit }, id) {
    try {
      await axios.post(`/v1/users/${id}/reset_password`);
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getUsers({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/users${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getAllUsers({ commit }) {
    try {
      const response = await axios.get("/v1/users");
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getUser({ commit }, id) {
    try {
      const response = await axios.get(`/v1/users/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async signup({ commit }, payload) {
    try {
      await axios.post("/v1/users", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.user_added"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateUser({ commit }, payload) {
    try {
      await axios.patch(`/v1/users/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteUser({ commit }, payload) {
    try {
      await axios.delete(`/v1/users/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("common.users_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateUserProfile({ commit }, payload) {
    try {
      const response = await axios.post("/updateUser", payload);
      messageUtils.showSuccess(i18n.t("common.success"));
      return response.user;
    } catch (error) {
      messageUtils.showErrors(error.response.data.message);
      return false;
    }
  },
};

export const mutations = {
  setUser(state, user){
    state.user = user;
  },

  setCustomer(state, customer){
    state.customer = customer;
  },

  setShouldFetchUsers(state, value) {
    state.shouldFetchUsers = value;
  },
  setSelectedTabRole(state, role) {
    state.selectedTabRole = role;
  },

  setNewAddUserDetails(state, payload) {
    state.newAddUserData.first_name = payload.first_name;
    state.newAddUserData.last_name = payload.last_name;
    state.newAddUserData.email = payload.email;
    state.newAddUserData.phone_number = payload.phone_number;
  },

  setNewAddUserCollapseData(state, payload) {
    state.newAddUserData.user_role_sites =
      payload.mappedUserRoleSites;
  },

  setLoadedSites(state, payload) {
    state.loadedSites = payload;
  },

  setCollapsesData(state, payload) {
    let ifExistsIndex = state.collapsesData.findIndex(
      (data) => data.collapse === payload.collapse
    );
    if (ifExistsIndex !== -1) {
      state.collapsesData[ifExistsIndex] = {
        collapse: payload.collapse,
        site_id: payload.site_id,
        role_ids: payload.role_ids,
        alreadyChecked: payload.alreadyChecked,
        loadedChecklists: payload.loadedChecklists
      };
    } else {
      state.collapsesData.push({
        collapse: payload.collapse,
        site_id: payload.site_id,
        role_ids: payload.role_ids,
        alreadyChecked: payload.alreadyChecked,
        loadedChecklists: payload.loadedChecklists
      });
    }
  },

  setDisabledSite(state, payload) {
    let foundSiteIndex = state.loadedSites.findIndex(
      (loadedSite) => loadedSite.id === payload.selectedSiteId
    );
    if (foundSiteIndex !== -1) {
      state.loadedSites[foundSiteIndex].disabled = payload.disabled;
    }
  },

  removeCollapseData(state, collapse) {
    let foundCollapseDataIndex = state.collapsesData.findIndex(
      (data) => data.collapse === collapse
    );
    state.collapsesData.splice(foundCollapseDataIndex, 1);
  },

  removeAllCollapseData(state) {
    state.collapsesData = [];
  },

  setAllPermissionsGrouped(state, payload) {
    state.allPermissionsGrouped = payload;
  },
};
