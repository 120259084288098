export const fieldsViewValidations = {
  data() {
    return {
      addFieldFormRules: {
        name: [
          {
            required: true,
            message: this.$t("fields.validations.enter_name"),
            trigger: "blur",
          },
        ],
        site_id: [
          {
            required: true,
            validator: this.siteFieldValidator,
            trigger: "blur",
          },
        ]
      },
    };
  },
};
