<template lang="pug">
  .sites-filter
    span.filter-title {{ $t('common.site_filter') }}
    el-select(
      v-model='selectedSiteId'
      filterable
      :placeholder="$t('placeholder.select_site')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='site in sites'
        :key='site.id'
        :label='site.name'
        :value="site.value !== '' ? site.id : site.value"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterSiteDropdown",

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedSiteId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeSiteIDFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  data() {
    return {
      sites: [],
    };
  },

  async mounted() {
    if (this.computeSiteIDFilter) {
      await this.handleGetSites()
    }
  },

  methods: {
    ...mapActions("site", ["getSites"]),

    async handleGetSites() {
      const sites = await this.getSites("?page_size=1000");
      this.sites = sites.data;
      this.sites.unshift({
        id: 0,
        name: "All",
        value: "",
      });
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
