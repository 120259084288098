<template lang="pug">
  el-form(
    :model="form"
    ref="EditProfileSettings"
    :rules="profileDataFormRules"
    @submit.native.prevent
  )
    h3.mb-3.mt-5 {{ $t('settings.edit_profile') }}
    el-row.mt-3.responsive(:gutter="20")
      el-col(:span="15")
        el-form-item(
          prop="first_name"
          :label="$t('settings.label.first_name')"
        )
          el-input(
            v-model="form.first_name"
            name="name"
          )
    el-row.mt-3.responsive(:gutter="20")
      el-col(:span="15")
        el-form-item(
          prop="last_name"
          :label="$t('settings.label.last_name')"
        )
          el-input(
            v-model="form.last_name"
            name="last_name"
          )
    el-row.mt-3.responsive(:gutter="20")
      el-col(:span="15")
        el-form-item(
          prop="phone_number"
          :label="$t('settings.label.phone_number')"
        )
          el-input(
            v-model="form.phone_number"
            name="phone_number"
          )
    el-row.mt-3.responsive(:gutter="20")
      el-col(:span="15")
        el-form-item(
          prop="email"
          :label="$t('settings.label.email')"
        )
          el-input(
            :readonly="true"
            v-model="form.email"
            name="email"
          )
    el-row.mt-3.responsive(:gutter="20")
      el-button.dark-green-btn.mt-3.settings-btn(
        @click="handleUpdateProfile('EditProfileSettings')"
        :loading="loadingUpdateProfile"
      ) {{ $t('settings.save') }}
</template>

<script>
import { mapActions, mapState } from "vuex";
import { settingsViewValidations } from "@/src/utils/formValidations/settingsViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "ProfileDataForm",
  mixins: [settingsViewValidations, customValidations],

  data() {
    return {
      imageFile: null,

      form: {
        first_name: null,
        last_name: null,
        phone_number: null,
        profile_image: null,
        email: null
      },

      loadingChangeEmail: false,
      loadingUpdateProfile: false,
    };
  },

  computed: {
    ...mapState("auth", ["access_token", "refresh_token", "user"]),
  },

  watch: {
    user() {
      this.initForm();
    },
  },

  mounted() {
    this.initForm();
  },

  methods: {
    ...mapActions({
      updateProfile: "user/updateProfile",
      sessionUser: "auth/sessionUser"
    }),

    initForm() {
      if (!this.user) return false;
      const { email, first_name, last_name, phone_number } = this.user;

      this.email = email;
      this.form.first_name = first_name;
      this.form.last_name = last_name;
      this.form.phone_number = phone_number;
      this.form.email = email;
    },

    handleUpdateProfile(formName) {
      this.loadingUpdateProfile = true;

      const form = this.$refs[formName];
      form.validate(async (valid) => {
        if (valid) {
          const payload = { ...this.form };

          this.updateProfile(payload)
            .then(async () => {
              await this.sessionUser({
                access_token: $cookies.get("access_token"),
                refresh_token: $cookies.get("refresh_token")
              });
            })
            .finally(() => {
              this.loadingUpdateProfile = false;
            });
        } else {
          this.loadingUpdateProfile = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;

    .el-col {
      width: 100%;
    }
  }
}

.el-form {
  // padding: 0 60px;

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>