import Vue from "vue";
import VueI18n from "vue-i18n";

import ro from "./lang/ro.json";
import en from "./lang/en.json";

import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from "./constants";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages: {
    en,
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
    ro: {
      currency: {
        style: 'currency',
        currency: 'RON',
      },
    },
  },
});

export default i18n;
