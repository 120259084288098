<template lang="pug">
  .add-user-view
    h3 {{ $t('users.add_new_user') }}
    el-breadcrumb.last-breadcrumb-bold.mt-3(separator="/")
      el-breadcrumb-item(:to="{ path: '/users' }") {{ $t('users.users') }}
      el-breadcrumb-item {{ $t('users.add_new_user') }}
    .add-user-wrapper.pt-4.position-relative
      el-row.pl-4.pr-4
        el-col(:span="24")
          UserDetailsForm(ref="userDetails")
        el-button.dark-green-btn.pop-up-btn.add-user-btn.position-absolute(
          @click="handleAddNewUser('AddUserForm')"
          v-loading="loading"
        ) {{ $t('dialogs.common.create') }}
      .hr-custom
      .custom-accordion
        el-row.pl-4.pr-4
          el-col(:span="24")
            el-collapse(v-model="activeNames" accordion)
              Collapse(
                v-for="(collapse, index) in collapses"
                :key="collapse"
                ref="collapseDetails"
                :isEdit='false'
                :collapse="collapse"
                :collapses="collapses"
                :index="index"
                @removeCollapse="handleRemoveCollapse"
              )
      el-button.ml-4.mt-4.dark-green-btn.pop-up-btn(
        @click="handleAddCollapse"
      ) {{ $t('common.add_more') }}
</template>

<script>
import _ from "lodash";
import UserDetailsForm from "@components/users/UserDetailsForm";
import Collapse from "@components/users/Collapse";
import RolePermissionsList from "@components/_shared/RolePermissionsList";
import { mapActions, mapMutations, mapState } from "vuex";

const requiredPermissionsToCreateUser = [
  // {
  //   groupName: 'Cost center',
  //   area_names: ['index'] // read all //@todo check if you really need a read all permissions to create a user
  // },
  /**
   * example below with multiple permissions by a group
   */
  // {
  //   groupName: 'Inventory bike',
  //   area_names: ['create', 'by_date_range']
  // }
]

export default {
  name: "AddUserView",
  components: { UserDetailsForm, Collapse, RolePermissionsList },

  computed: {
    ...mapState("user", ["newAddUserData", "collapsesData", "allPermissionsGrouped"]),
  },

  data() {
    return {
      activeNames: [0],
      collapses: [0],
      loading: false,
    };
  },

  destroyed() {
    this.removeAllCollapseData()
  },

  created() {
    this.handleGetCostCenters();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("user", ["signup"]),
    ...mapMutations("user", [
      "setLoadedCostCenters",
      "setNewAddUserCollapseData",
      "removeAllCollapseData"
    ]),

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("?page_size=1000");
      cost_centers.data.forEach((costCenter) => {
        costCenter.disabled = false;
      });

      this.setLoadedCostCenters(cost_centers.data);
    },

    handleAddCollapse() {
      let lastItem = _.clone(this.collapses[this.collapses.length - 1]);
      this.collapses.push((lastItem += 1));
      this.activeNames = this.collapses.length - 1
    },

    handleRemoveCollapse(index) {
      if (this.collapses.length > 1) {
        this.collapses.splice(index, 1);
      }
    },

    async handleAddNewUser() {
      let isUserDetailsValid = false;
      let isRolePermissionValid = false;

      this.$refs.userDetails.$refs["AddUserForm"].validate((valid) => {
        isUserDetailsValid = !!valid;
      });

      this.$refs.collapseDetails.forEach((collapse) => {
        collapse.$refs["RolesAndPermissionsForm"].validate((valid) => {
          isRolePermissionValid = !!valid;
        });
      });

      if (isUserDetailsValid && isRolePermissionValid) {
        this.handleMapCollapseData();

        let payload = this.newAddUserData;

        if (!this.checkRequiredPermissions(this.collapsesData)) {
          return this.$message({
            type: "warning",
            message: this.$t('users.validations.select_read_all'),
          });
        }

        this.loading = true;
        await this.signup(payload).then(() => {
          this.$router.push("/users");
        }).finally(() => {
          this.loading = false;
        })
      }
    },

    handleMapCollapseData() {
      let mappedUserRoleCostCenters = [];
      let mappedUserCostCenterPermissions = [];

      const userRoleCostCenterData = (role_id, cost_center_id) => {
        return {
          role_id: Number(role_id),
          cost_center_id: Number(cost_center_id),
        };
      };

      const userRoleCostCenterPermissions = (
          cost_center_id,
          permission_id,
          grant_type
      ) => {
        return {
          cost_center_id: Number(cost_center_id),
          permission_id: Number(permission_id),
          grant_type,
        };
      };

      this.collapsesData.forEach((collapseData) => {
        // map user_role_cost_centers
        collapseData.role_ids.forEach((role_id) => {
          mappedUserRoleCostCenters.push(
              userRoleCostCenterData(role_id, collapseData.cost_center_id)
          );
        });

        // map user_cost_center_permissions
        collapseData.permissions.forEach((perm) => {
          mappedUserCostCenterPermissions.push(
              userRoleCostCenterPermissions(
                  collapseData.cost_center_id,
                  perm.permission_id,
                  perm.grant_type
              )
          );
        });
      });

      this.setNewAddUserCollapseData({
        mappedUserRoleCostCenters,
        mappedUserCostCenterPermissions,
      });
    },

    checkRequiredPermissions(collapsesData) {
      let requiredIds = []
      let requiredIdsPresentInAllCollapses = []

      // extract the required ids by area_name
      requiredPermissionsToCreateUser.forEach(requiredPermissions => {
        let groupName = requiredPermissions.groupName
        let areaNames = requiredPermissions.area_names

        // extract the required ids
        let groupPermissionsArray = this.allPermissionsGrouped[groupName];
        groupPermissionsArray.forEach(permission => {
          if (areaNames.includes(permission.area_name)) {
            requiredIds.push(permission.id)
          }
        })
      })
      
      // check if requiredIds match every collapse alreadyChecked
      collapsesData.forEach(collapse => {
        let collapseAlreadyCheckedIds = collapse.alreadyChecked

        requiredIdsPresentInAllCollapses.push(
            requiredIds.every(id => collapseAlreadyCheckedIds.includes(id))
        )
      })

      // allow creation if required perm is present in all collapses
      return requiredIdsPresentInAllCollapses.every(element => element === true)
    }
  },
};
</script>

<style lang="scss" scoped>
.add-user-btn {
  right: 24px;
}
</style>
