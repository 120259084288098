<template lang="pug">
 .site-view
    h3 {{ $t('sites.sites') }}
    .site-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="true"
        )
          el-button.dark-green-btn(
            @click="handleOpenAddSiteDialog"
          )
            h4 {{ $t('sites.actions.add') }}
      el-row
        el-col(:span='24')
          SitesViewTable(
            v-loading="loading"
            :sites="sites"
          )
    Pagination(
      :view="'sites'"
      :search="search"
      @viewData="handleGetSites"
      @handleTableLoading="handleLoading"
    )

</template>

<script>
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { general } from "@src/services/dynamicViews/general";
import SitesViewTable from "@components/sites/SitesViewTable";
import { mapMutations, mapState } from "vuex";

export default {
  name: 'SitesView',
  mixins: [general],
  components: { Pagination, SearchWithButtons, SitesViewTable },

  data() {
    return {
      search: "",
      sites: [],
      loading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["showAddSiteDialog"]),

    handleGetSites(data) {
      this.sites = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    handleOpenAddSiteDialog() {
      this.showAddSiteDialog();
    },
  },
};
</script>
