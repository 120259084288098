<template lang="pug">
  .sensor-view(v-if="transmitterId != null")
    h3 {{ $t('sensors.sensors_init') + sensorName + $t('sensors.sensors_last') }}
    .sensors-wrapper
      .actions
        el-button.dark-green-btn(
          @click="handleAddSensor()"
          :loading="loadingAddBtn"
        ) {{ $t('sensors.actions.add') }}
      el-row
        el-col(:span='24')
          SoilSensorsViewTable(
            v-loading="loadingTable"
            :soilSensors="soilSensors"
          )
    Pagination(
      :view="'soil_sensors'"
      :transmitterId="transmitterId"
      @viewData="handleGetSoilSensors"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import SoilSensorsViewTable from "@components/soil_sensors/SoilSensorsViewTable";
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";

export default {
  name: "ManageSensorsView",
  mixins: [filterUtils],
  components: { Pagination, SoilSensorsViewTable },
  data() {
    return {
      sensorName: null,
      soilSensors: [],
      loadingTable: false,
      loadingAddBtn: false,

      transmitter: null,
      transmitterId: null,

      loading: false,
    };
  },

  async created() {
    await this.handleGetUserDetails()
    // this.transmitterId = this.transmitter.id;
  },

  computed: {
    ...mapState("auth", ["user"]),
    // ...mapState("transmitter", ["transmitter"]),
  },

  watch: {
    transmitterId(){
      this.sensorName = this.transmitter.name
    }
  },

  methods: {
    ...mapMutations('soilSensor', ['setShouldFetchSoilSensors']),

    ...mapActions('soilSensor', ['addSoilSensor']),
    ...mapActions('transmitter', ['getTransmitter']),

    async handleGetUserDetails() {
      let id = this.$route.params.id
      const response = await this.getTransmitter(id);
      this.transmitter = response.data;
      this.transmitterId = this.transmitter.id;
    },

    handleGetSoilSensors(data) {
      this.soilSensors = data;
    },

    handleLoading(loading) {
      this.loadingTable = loading
    },

    handleAddSensor(){
      this.loadingAddBtn = true;

      this.addSoilSensor(this.transmitter)
        .then(() => {
          this.setShouldFetchSoilSensors(true);
        })
        .finally(() => {
          this.loadingAddBtn = false;
        });
    },

  }
};
</script>
