<template lang="pug">
  .fields-filter
    el-select(
      v-model='selectedFieldId'
      filterable
      :placeholder="$t('placeholder.select_field')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='field in fields'
        :key='field.id'
        :label='field.name'
        :value="field.value !== '' ? field.id : field.value"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "FilterFieldDropdown",

  props: {
    value: {
      type: Number,
      default: null,
    },
  },

  computed: {
    selectedFieldId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),
    ...mapState("site", ["clientSite"]),

  },

  data() {
    return {
      fields: [],
    };
  },

  // async created() {
  //   await this.handleGetFields()
  // },

  watch: {
    clientSite(){
      this.handleGetFields()
    }
  },

  methods: {
    ...mapActions("field", ["getFieldsList"]),

    async handleGetFields() {
      const response = await this.getFieldsList(`?page_size=1000&by_site_id=${this.clientSite.id}`);
      this.fields = response;

      // this.fields.unshift({
      //   id: 0,
      //   name: "All",
      //   value: "",
      // });
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
