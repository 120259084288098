import i18n from "@i18n";
import { roles } from "@utils/roles";

export const NUMBER_REGEX = new RegExp(/^[+]?\d+(\.\d+)?$/);
export const PHONE_NUMBER_REGEX = new RegExp(/^[+]*(?:[\d]\s*){8,15}$/);
export const EMAIL_VALIDATOR_REGEX = new RegExp(
  /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
);
export const SERIAL_NUMBER_REGEX = new RegExp(/^.{3,}$/);
export const LATITUDE_REGEX = new RegExp(
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,16})?))$/
);
export const LONGITUDE_REGEX = new RegExp(
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,16})?))$/
);
export const BIKE_SERIAL_NUMBER_REGEX = new RegExp(/^.{3,}$/);
export const BIKE_RFID_REGEX = new RegExp(/^.{3,}$/);

export const CUI_REGEX = new RegExp(/^[0-9]{1,12}$/);

export const TRADE_REGISTER_REGEX = new RegExp(/^[JFC](([0-9]|[1-3][0-9]|40)|40|51|52)\/[0-9]+\/[0-9]+$/i);

export const ADMIN_ROLES = [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR]

export const customValidations = {
  methods: {
    siteValidator(rule, value, callback) {
      if(!(ADMIN_ROLES.includes(this.form.role_name))) {
        if (value === "" || value === null || value === 0) {
          callback(new Error(i18n.t("users.validations.enter_site")));
        }
        else {        
          callback();
        }
      }else {
        if (value != "" && value != null && value != 0) {
          callback(new Error(i18n.t("users.validations.site_with_admin")));
        }else {        
          callback();
        }
      }
      
    },

    siteFieldValidator(rule, value, callback) {
      // console.log("fgfgfgf" + value)
      if (value === "" || value === null || value === 0) {
        callback(new Error(i18n.t("users.validations.enter_site")));
      }
      else {        
        callback();
      }
    },

    fullNameValidator(rule, value, callback) {
      if (value === "" || value === null || value.trim().length === 0) 
      {  
        callback(new Error(i18n.t("users.validations.enter_name")));
      } else if (value.split(" ").length < 2)
      {
        callback(new Error(i18n.t("users.validations.enter_last_name")));
      }else{
        callback();
      }
    },
    
    emailValidator(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error(i18n.t("auth.validations.enter_email")));
      } else if (
        this.form.email_confirmation &&
        this.form.email !== this.form.email_confirmation
      ) {
        callback(
          new Error(i18n.t("common.validations.confirmation_email_not_same"))
        );
      } else if (EMAIL_VALIDATOR_REGEX.test(value)) {
        callback();
      } else {
        callback(new Error(i18n.t("common.validations.enter_email_valid")));
      }
    },

    phoneNumberValidator(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error(i18n.t("settings.validations.enter_phone_number")));
      } else if (PHONE_NUMBER_REGEX.test(value)) {
        callback();
      } else {
        callback(
          new Error(i18n.t("settings.validations.enter_phone_number_valid"))
        );
      }
    },

    validateConfirmationPassword(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error(i18n.t("settings.validations.enter_password")));
      } else if (this.form.password !== this.form.password_confirmation) {
        callback(new Error(i18n.t("settings.validations.passwords_do_not_match")));
      } else {
        callback();
      }
    },

    serialNumberValidator(rule, value, callback) {
      if (value === "" || value === null) {
        callback(
          new Error(i18n.t("common.validations.serial_number"))
        );
      } else if (SERIAL_NUMBER_REGEX.test(value)) {
        callback();
      } else {
        callback(
          new Error(
            i18n.t("docking_stations_list.validations.serial_number_valid")
          )
        );
      }
    },

    latitudeValidator(rule, value, callback) {
      if (value === "" || value === null) {
        callback(
          new Error(i18n.t("docking_stations_list.validations.latitude"))
        );
      } else if (LATITUDE_REGEX.test(value)) {
        callback();
      } else {
        callback(
          new Error(i18n.t("docking_stations_list.validations.latitude_valid"))
        );
      }
    },

    longitudeValidator(rule, value, callback) {
      if (value === "" || value === null) {
        callback(
          new Error(i18n.t("docking_stations_list.validations.longitude"))
        );
      } else if (LONGITUDE_REGEX.test(value)) {
        callback();
      } else {
        callback(
          new Error(i18n.t("docking_stations_list.validations.longitude_valid"))
        );
      }
    },

    bikeSerialNumberValidator(rule, value, callback) {
      if (value === "" || value === null || value.trim().length === 0) {
        callback(new Error(i18n.t("inventory.validations.bike_serial_number")));
      } else if (BIKE_SERIAL_NUMBER_REGEX.test(value)) {
        callback();
      } else {
        callback(
          new Error(i18n.t("inventory.validations.bike_serial_number_valid"))
        );
      }
    },

    bikeRfidValidator(rule, value, callback) {
      if (value === "" || value === null || value.trim().length === 0) {
        callback(new Error(i18n.t("inventory.validations.bike_rfid")));
      } else if (BIKE_RFID_REGEX.test(value)) {
        callback();
      } else {
        callback(new Error(i18n.t("inventory.validations.bike_rfid_valid")));
      }
    },

    logsSizeValidator(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error(i18n.t("common.validations.enter_number")));
      } else if (
        this.form.insert_reserved_log_size_unit === "MB" ?
          this.form.insert_reserved_log_size > this.form.max_log_size * 1000 : // MB multiplier
          this.form.insert_reserved_log_size > this.form.max_log_size
      ) {
        callback(
          new Error(i18n.t("logs.validations.exceeds_total_log_size"))
        );
      } else if (NUMBER_REGEX.test(value)) {
        callback();
      } else {
        callback(new Error(i18n.t("common.validations.enter_valid_number")));
      }
    },
    //
    // cuiValidator(rule, value, callback) {
    //   if (value === "" || value === null) {
    //     callback(new Error(i18n.t("cost_centers.validations.enter_cui")));
    //   } else if (CUI_REGEX.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error(i18n.t("cost_centers.validations.valid_cui")));
    //   }
    // },
    //
    // tradeRegisterValidator(rule, value, callback) {
    //   if (value === "" || value === null) {
    //     callback(new Error(i18n.t("cost_centers.validations.enter_commercial_register")));
    //   } else if (TRADE_REGISTER_REGEX.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error(i18n.t("cost_centers.validations.valid_commercial_register")));
    //   }
    // },
  },
};
