<template lang="pug">
  el-row(v-loading="loading")
    el-col(
      :span="24"
    ).mt-2
      GmapMap.mapWrapperClient(
        v-if="clientMap"
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="hybrid"
        :style="style"
      )
      GmapMap.mapWrapper(
        v-if="!clientMap"
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="hybrid"
        :style="style"
      )
</template>

<script>
import customMarker from "@assets/dockingStationsMap/available-location.svg";
import startMarker from "@assets/rideHistory/pin-start.svg";
import endMarker from "@assets/rideHistory/pin-end.svg";
import { gmapApi } from "vue2-google-maps";
import { mapState } from "vuex";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";
import {toRaw} from 'vue';
import i18n from "@i18n";
import {filterUtils} from "@utils/filterUtils";

export default {
  name: "GoogleMaps",
  mixins: [filterUtils],
  props: {
    dialogName: {
      type: String,
      required: false,
    },

    rideCoordinates: {
      type: Array
    },

    sensors: {
      type: Array,
      default: () => [],
    },

    soilParam: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState("map", ["coordinates", "sensing_radius", "changedDialog", "isGoogleLoaded"]),
    ...mapState("field", ["selectedFieldInfoSettings"]),

    google: gmapApi,
  },

  data() {
    return {
      clientMap: false,
      markers: [],
      circles: [],
      startMarker: null,
      endMarker: null,
      flightPath: null,
      marker: null,
      markerText: 0,
      circle: null,
      loading: false,
      zoom: 5,
      style:'width:400px;height:300px',

      markerPosition: {
        lat: null,
        lng: null,
      },

      center: {
        lat: 0,
        lng: 0,
      },

      userLocation: null,
    };
  },

  watch: {
    google() {
      this.initMap()
    },

    coordinates() {
      this.initMap()
    },

    sensing_radius(){
      this.initMap()
    },

    rideCoordinates() {
      this.initMap()
    },

    sensors() {
      this.initMap()
    },

    soilParam(){
      this.initMap()
    }
  },

  async created() {
    await this.getUser2Location();
  },

  async mounted() {
    await this.getUser2Location();
    if (this.google) {
      this.initMap()
    }

  },

  methods: {
    async initMap() {
      switch (this.dialogName) {
        case "AddSite":
        case "EditSite":
          return this.handleMapMarker()
        case "viewRideDetails":
          return this.handleDrawPathFromCoordinates()
        case "EditTransmitter":
          return this.handleMapMarkerForTransmitter();
        case "EditSensor":
          return this.handleMapMarkerAndCicleForSensor();
        case "Overview":
          this.clientMap = true;
          return this.handleMapMarkerAndCicleForOverviewMap();
      }
    },

    async handleMapMarkerAndCicleForOverviewMap(){
      this.markers.map((marker) => toRaw(marker).setMap(null))
      this.circles.map((circle) => toRaw(circle).setMap(null))

      this.markers = []
      this.circles = []

      this.style="width: 400px; height: 620px"
      if (this.sensors.length === 0) {
        return;
      }

      this.center.lat = Number(this.sensors[0].latitude);
      this.center.lng = Number(this.sensors[0].longitude);
      this.zoom = 20;
      
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.sensors.forEach((sensor) => {
        //   // marker - pop up
          const contentString =
              '<div id="content" class="info-box">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<h4 id="firstHeading" class="text-center border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4">${sensor.sensor_number} ${this.renderSensorType(sensor)}</h4>` +
              '<div class="hr-custom"></div>' +
              '<div id="bodyContent" class="pl-3 pr-3 pb-1">' +
              `<h5 class="text-center pt-2 pb-2"></h5>
                  <p class="margin-0  text-center align-items-center justify-content-center font-weight-normal mb-2"><b>${sensor.transmitter_name}</b> ${sensor.transmitter_dev_eui}</p>
                  <div class="hr-custom"></div>
                  <br/>
                  ${this.renderSensorInfo(sensor)}
                ` +
              "</div>" +
              "</div>";
                //  ${this.renderInfoBoxStatus(dock)}
                //  ${this.renderInfoBoxBikesStatus(dock)}
          const infoWindow = new this.google.maps.InfoWindow({
            content: contentString,
          });

        // marker

          let marker = null   

            marker = new this.google.maps.Marker({
              position: {
                lat: Number(sensor.latitude),
                lng: Number(sensor.longitude),
              },
              label: {
                color: '#ffffff', fontWeight: 'bold', fontSize: '14px',
                text: this.markerText.toString(),
              },
              // icon: this.pinSymbol('green'),
              // map: map,
              // icon: markerIcon,
              draggable: false,
            });

            marker.addListener("click", () => {
              infoWindow.open({
                anchor: marker,
                // map,
                shouldFocus: false,
              });
            });

            this.markers.push(marker)

        //circle

        let circle = null;

        const fillColor = this.handleColorByParam(sensor);

          circle = new this.google.maps.Circle({
            // map: map,
            fillColor: fillColor,
            strokeColor: fillColor,
            strokeWeight: 0,
            // fillOpacity: 1,
            strokeWidth: 5,
            center: {
              lat: Number(sensor.latitude),
              lng: Number(sensor.longitude),
            },
            radius: sensor.sensing_radius
          });

          this.circles.push(circle)
        });

        this.markers.map((marker) => toRaw(marker).setMap(map))
        this.circles.map((circle) => toRaw(circle).setMap(map))

      });
    },

    renderSensorInfo(sensor){
    let sensorInfo = '';
      if (sensor.last_report) {
        sensorInfo = 
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.created_at")}: </b>${this.computeSensorDateFormat(sensor.last_report.created_at)}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.humidity")}: </b>${sensor.last_report.soil_info.humidity} ${i18n.t("sensor_infos.um.humidity")}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.temperature")}: </b>${sensor.last_report.soil_info.temperature} ${i18n.t("sensor_infos.um.temperature")}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.conductivity")}: </b>${sensor.last_report.soil_info.conductivity} ${i18n.t("sensor_infos.um.conductivity")}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.ph")}: </b>${sensor.last_report.soil_info.ph} ${i18n.t("sensor_infos.um.ph")}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.nitrogen")}: </b>${sensor.last_report.soil_info.nitrogen} ${i18n.t("sensor_infos.um.nitrogen")}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.phosphorus")}: </b>${sensor.last_report.soil_info.phosphorus} ${i18n.t("sensor_infos.um.phosphorus")}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("sensor_infos.potassium")}: </b>${sensor.last_report.soil_info.potassium} ${i18n.t("sensor_infos.um.potassium")}</h5>`
        // sensorInfo = 
        //   `<h5 id="firstHeading" class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4" title="Serie"><b class="pt-2 pb-2">${i18n.t("bikes-map.bike-serial-number")}: </b>${sensor.last_report.soil_info.humidity}</h5>` +
        //   `<h5 id="firstHeading" class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("bikes-map.bike-rfid-code")}: </b> ${sensor.last_report.soil_info.humidity}</h5>` +
        //   `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("bikes-map.bike-type")}: </b>${sensor.last_report.soil_info.humidity}</h5>`
      }
      return sensorInfo;
    },

    handleColorByParam(sensor) {
      if(sensor.last_report && this.soilParam){
        switch (this.soilParam.info_type) {
          case 'humidity':
            this.markerText = sensor.last_report.soil_info.humidity;
            return this.handleInfoColor(sensor.last_report.soil_info.humidity);
          case 'temperature':
            this.markerText = sensor.last_report.soil_info.temperature;
            return this.handleInfoColor(sensor.last_report.soil_info.temperature);
          case 'conductivity':
            this.markerText = sensor.last_report.soil_info.conductivity;
            return this.handleInfoColor(sensor.last_report.soil_info.conductivity);
          case 'ph':
            this.markerText = sensor.last_report.soil_info.ph;
            return this.handleInfoColor(sensor.last_report.soil_info.ph);
          case 'nitrogen':
            this.markerText = sensor.last_report.soil_info.nitrogen;
            return this.handleInfoColor(sensor.last_report.soil_info.nitrogen);
          case 'phosphorus':
            this.markerText = sensor.last_report.soil_info.phosphorus;
            return this.handleInfoColor(sensor.last_report.soil_info.phosphorus);
          case 'potassium':
            this.markerText = sensor.last_report.soil_info.potassium;
            return this.handleInfoColor(sensor.last_report.soil_info.potassium);
          default:
            return 'not found';
        }  
      }
    },

    handleInfoColor(info){
      if(info >= this.soilParam.range1s && info < this.soilParam.range1e){
        return this.soilParam.range1color
      }else if(info >= this.soilParam.range2s && info < this.soilParam.range2e){
        return this.soilParam.range2color
      }else if(info >= this.soilParam.range3s && info < this.soilParam.range3e){
        return this.soilParam.range3color
      }
    },

    renderSensorType(sensor){
      if(sensor.sensor_type)
        switch (sensor.sensor_type) {
          case '0e':
            return 'NPK';
          case 'ee':
            return 'THCNPK';
          case 'de':
            return 'THPHNPK';
          case '10':
            return 'PH';
          case 'c0':
            return 'TH';
          case 'e0':
            return 'THC';
          case 'f0':
            return 'THCPH';
          case 'd0':
            return 'THPH';
          case 'fe':
            return 'THCPHNPK';
          default:
            return 'THC';
        }
    },

    async handleMapMarkerAndCicleForSensor(){
      if(this.coordinates.lat && this.coordinates.lng) {
        this.center.lat = Number(this.coordinates.lat);
        this.center.lng = Number(this.coordinates.lng);
        this.markerPosition.lat = Number(this.coordinates.lat);
        this.markerPosition.lng = Number(this.coordinates.lng);
      } else {
        this.center.lat = this.userLocation.coords.latitude;
        this.center.lng = this.userLocation.coords.longitude;
        this.markerPosition.lat = this.userLocation.coords.latitude;
        this.markerPosition.lng = this.userLocation.coords.longitude;
        this.$emit("markerDragEnd", '',this.markerPosition);
      }

      let geocoder = new this.google.maps.Geocoder();

      const geocodePosition = (pos) => {
        geocoder.geocode(
            {
              latLng: pos,
            },
            (responses) => {
              if (responses && responses.length > 0) {
                this.$emit(
                    "markerDragEnd",
                    responses[0].formatted_address,
                    this.markerPosition
                );
              } else {
                this.$message({
                  type: "warning",
                  message: this.$t("map.cannot_determine_address"),
                });
              }
            }
        );
      };

      this.$refs.mapRef.$mapPromise.then((map) => {
        if (this.marker) {
          this.google.maps.event.clearInstanceListeners(this.marker);
          this.marker.setMap(null);
          this.marker = null;
        }

        if (this.circle) {
          this.google.maps.event.clearInstanceListeners(this.circle);
          this.circle.setMap(null);
          this.circle = null;
        }


        this.marker = new this.google.maps.Marker({
          position: {
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          },
          map: map,
          // icon: customMarker,
          draggable: true,
        });

        this.circle = new this.google.maps.Circle({
            map: map,
            // fillColor:'red',
            center: {
              lat: this.markerPosition.lat,
              lng: this.markerPosition.lng,
            },
            radius: this.sensing_radius
        });

        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo({
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          });

          this.loading = false;
        });

        this.google.maps.event.addListener(this.marker, "dragend", () => {
          this.markerPosition.lat = this.marker.getPosition().lat();
          this.markerPosition.lng = this.marker.getPosition().lng();
          geocodePosition(this.marker.getPosition());
        });
      });
      
    },

    async handleMapMarkerForTransmitter(){
      if(this.coordinates.lat && this.coordinates.lng) {
        this.center.lat = Number(this.coordinates.lat);
        this.center.lng = Number(this.coordinates.lng);
        this.markerPosition.lat = Number(this.coordinates.lat);
        this.markerPosition.lng = Number(this.coordinates.lng);
      } else {
        this.center.lat = this.userLocation.coords.latitude;
        this.center.lng = this.userLocation.coords.longitude;
        this.markerPosition.lat = this.userLocation.coords.latitude;
        this.markerPosition.lng = this.userLocation.coords.longitude;
        this.$emit("markerDragEnd", '',this.markerPosition);
      }

      let geocoder = new this.google.maps.Geocoder();

      const geocodePosition = (pos) => {
        geocoder.geocode(
            {
              latLng: pos,
            },
            (responses) => {
              if (responses && responses.length > 0) {
                this.$emit(
                    "markerDragEnd",
                    responses[0].formatted_address,
                    this.markerPosition
                );
              } else {
                this.$message({
                  type: "warning",
                  message: this.$t("map.cannot_determine_address"),
                });
              }
            }
        );
      };

      this.$refs.mapRef.$mapPromise.then((map) => {
        if (this.marker) {
          this.google.maps.event.clearInstanceListeners(this.marker);
          this.marker.setMap(null);
          this.marker = null;
        }

        this.marker = new this.google.maps.Marker({
          position: {
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          },
          map: map,
          // icon: customMarker,
          draggable: true,
        });

        // this.circle = new this.google.maps.Circle({
        //     map: map,
        //     // fillColor:'red',
        //     center: {
        //       lat: this.markerPosition.lat,
        //       lng: this.markerPosition.lng,
        //     },
        //     radius: 16093 // 10 miles converted ot meters
        // });

        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo({
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          });

          this.loading = false;
        });

        this.google.maps.event.addListener(this.marker, "dragend", () => {
          this.markerPosition.lat = this.marker.getPosition().lat();
          this.markerPosition.lng = this.marker.getPosition().lng();
          geocodePosition(this.marker.getPosition());
        });
      });
    },

    pinSymbol(color) {
                // icon: this.pinSymbol('green'),
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
            fillColor: color,
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1
        };
    },

    handleDrawPathFromCoordinates() {
      if(this.startMarker) { this.startMarker.setMap(null) }
      if(this.endMarker) { this.endMarker.setMap(null) }
      if(this.flightPath) { this.flightPath.setMap(null) }
      if (this.rideCoordinates.length > 0) {
        const mappedRideCoordinates = this.rideCoordinates.map(coords => {
          return {
            lat: Number(coords.latitude),
            lng: Number(coords.longitude)
          }
        })

        this.flightPath = new this.google.maps.Polyline({
          path: mappedRideCoordinates,
          geodesic: true,
          strokeColor: "#2564f0",
          strokeWeight: 5,
        });

        this.$refs.mapRef.$mapPromise.then((map) => {
          // pan to the start coordinate
          map.panTo({
            lat: mappedRideCoordinates[0].lat,
            lng: mappedRideCoordinates[0].lng,
          });

          this.flightPath.setMap(map);

          // add start/end markers
          this.startMarker = new this.google.maps.Marker({
            position: {
              lat: mappedRideCoordinates[0].lat,
              lng: mappedRideCoordinates[0].lng,
            },
            map: map,
            icon: startMarker,
            draggable: false,
          });
          this.endMarker = new this.google.maps.Marker({
            position: {
              lat: mappedRideCoordinates[mappedRideCoordinates.length - 1].lat,
              lng: mappedRideCoordinates[mappedRideCoordinates.length - 1].lng,
            },
            map: map,
            icon: endMarker,
            draggable: false,
          });
        })
      }
    },

    async handleMapMarker() {
      const location = await this.getUserLocation();

      if (!this.coordinates.lat) {
        this.center.lat = location.coords.latitude;
        this.center.lng = location.coords.longitude;
        this.markerPosition.lat = location.coords.latitude;
        this.markerPosition.lng = location.coords.longitude;
      } else {
        this.center.lat = this.coordinates.lat;
        this.center.lng = this.coordinates.lng;
        this.markerPosition.lat = this.coordinates.lat;
        this.markerPosition.lng = this.coordinates.lng;
      }

      let geocoder = new this.google.maps.Geocoder();

      const geocodePosition = (pos) => {
        geocoder.geocode(
            {
              latLng: pos,
            },
            (responses) => {
              if (responses && responses.length > 0) {
                this.$emit(
                    "markerDragEnd",
                    responses[0].formatted_address,
                    this.markerPosition
                );
              } else {
                this.$message({
                  type: "warning",
                  message: this.$t("map.cannot_determine_address"),
                });
              }
            }
        );
      };

      this.$refs.mapRef.$mapPromise.then((map) => {
        if (this.marker) {
          this.google.maps.event.clearInstanceListeners(this.marker);
          this.marker.setMap(null);
          this.marker = null;
        }

        this.marker = new this.google.maps.Marker({
          position: {
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          },
          map: map,
          // icon: customMarker,
          draggable: true,
        });
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo({
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          });

          this.loading = false;
        });

        this.google.maps.event.addListener(this.marker, "dragend", () => {
          this.markerPosition.lat = this.marker.getPosition().lat();
          this.markerPosition.lng = this.marker.getPosition().lng();
          geocodePosition(this.marker.getPosition());
        });
      });
    },

    async getUserLocation() {
      let location = null;
      navigator.geolocation.getCurrentPosition(currentBrowserLocation => {
        location = currentBrowserLocation
      })

      // if location is not working or turned off
      const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
      if (romaniaCoords) {
        location = romaniaCoords
        if (!this.coordinates.lat) {
          this.zoom = 7
        }
      }

      return location;
    },

    async getUser2Location() {
      navigator.geolocation.getCurrentPosition(currentBrowserLocation => {
        this.userLocation = currentBrowserLocation;
      })

      // if location is not working or turned off
      const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
      if (romaniaCoords) {
        this.userLocation = romaniaCoords
        if (!this.coordinates.lat) {
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.mapWrapper {
  width: 100% !important;
  height: 340px !important;
  margin: 0 auto;
  margin-bottom: 32px;
}

.mapWrapperClient {
  width: 100% !important;
  height: 500px !important;
  margin: 0 auto;
  margin-bottom: 32px;
}

</style>
