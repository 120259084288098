<template lang="pug">
  el-menu-item(index='/fields')
    .img-wrapper
      img(src="@src/assets/sidebar/cost_centers.svg")
    router-link.sub-menu-link(to="/fields")
      span  {{ $t('sidebar.fields') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { mapState } from "vuex";


export default {
  name: "FieldsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },
}
</script>
