<template lang="pug">
  .add-user
    el-form(
      :model="form"
      v-loading="loadingForm"
      ref="EditUserForm"
      :rules="editUserFormRules"
      @submit.native.prevent
    )
      el-row
        el-col(center)
          el-row(:gutter="24")
            el-col(:span="12")
              el-form-item(
                prop="full_name"
                :label="$t('users.actions.label.full_name')"
              )
                el-input(
                  v-model="form.full_name"
                  name="full_name"
                )
            el-col(:span="12")
              el-form-item(
                prop="phone_number"
                :label="$t('users.actions.label.phone_number')"
              )
                el-input(
                  v-model="form.phone_number"
                  name="phone_number"
                )
          el-row(:gutter="24")
            el-col(:span="12")
              el-form-item(
                prop="email"
                :label="$t('users.actions.label.email')"
              )
                el-input(
                  v-model="form.email"
                  name="email"
                )
            el-col(:span="12")
              el-form-item(
                prop="password"
                :label="$t('users.actions.label.password')"
              )
                el-input(
                  :show-password="true"
                  v-model="form.password"
                  type="password"
                  name="password"
                )
          br
          el-row(:gutter="24")
            el-col(:span="12")
              el-form-item(
                prop="role_id"
                :label="$t('users.actions.label.user_role')"
              )
                el-select.w-100(
                  :selected="selected"
                  v-model='form.role_id'
                  filterable
                  allow-create
                  :placeholder="$t('users.actions.label.user_role')"
                )
                  el-option(
                    v-for='role in roles'
                    :key='role.id'
                    :label='role.name'
                    :value='role.id'
                  )
            el-col(:span="12")
              el-form-item(
                prop="site_id"
                :label="$t('users.actions.label.user_site')"
              )
                el-select.w-100(
                  v-model='form.site_id'
                  filterable
                  allow-create
                  :placeholder="$t('users.actions.label.user_site')"
                )
                  el-option(
                    v-for='site in sites'
                    :key='site.id'
                    :label='site.name'
                    :value='site.id'
                  )
    el-row.text-center
      el-button.dark-green-btn.mt-5.pop-up-btn(
        @click="handleAddNewUser('EditUserForm')"
        :loading="loading"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "../../_shared/GoogleMaps";
import { customValidations } from "@utils/customValidators";
import { usersViewValidations } from "@utils/formValidations/usersViewValidations";
import { currencyOptions, setObjectValues } from "@utils/generalUtils";
import _ from "lodash";
import Vue from "vue";

export default {
  name: "AddUser",
  mixins: [usersViewValidations, customValidations, currencyOptions],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      loadingForm: false,
      selected: '',
      form: {
        full_name: null,
        phone_number: null,
        email: null,
        password: null,
        role_id: null,
        site_id: null
      },
      sites: [],
      roles: [],

      loading: false,
      setObjectValues,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["changedDialog"]),
    ...mapState("user", ["customer"]),
  },

  watch: {
    user() {
      // this.handleGetFormData()
      // this.updateMapFromAddress();
    },
    role_id(){
      // console.log(form)
    }
  },

  async created() {
    await this.handleGetRoles();
    await this.handleGetSites();
    this.loadingForm = true
    this.handleGetFormData()
    this.loadingForm = false
  },

  async mounted() {
    await this.handleGetRoles();
    await this.handleGetSites();
  },

  methods: {
    ...mapActions("user", ["editUser"]),
    ...mapActions("site", ["getSites"]),
    ...mapActions("role", ["getRoles"]),

    ...mapMutations("dialog", ["hideEditUserDialog"]),

    ...mapMutations("user", [
      "setUser",
      "setCustomer",
      "setShouldFetchUsers",
    ]),

    handleGetFormData() {
      if (!this.customer) return false;
      // this.form = { ...this.customer }; 
      this.form.full_name = this.customer.first_name + " " + this.customer.last_name

      this.form.email = this.customer.email
      this.form.phone_number = this.customer.phone_number

      this.roles.forEach((role) => {
        if(role.id == this.customer.user_role_sites_ref.role_id){
          this.form.role_id = role.id
        }
      });

      this.sites.forEach((site) => {
        if(site.id == this.customer.user_role_sites_ref.site_id){
          this.form.site_id = site.id
        }
      });
    },

    async handleGetSites() {
      const sites = await this.getSites("?page_size=1000");
      this.sites = sites.data;
      this.sites.unshift({
        id: 0,
        name: "None",
        value: "",
      });
    },

    async handleGetRoles() {
      const roles = await this.getRoles("?page_size=1000");
      this.roles = roles;
      // this.roles.unshift({
      //   id: 0,
      //   name: "None",
      //   value: "",
      // });
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    handleAddNewUser(formName) {
      this.loading = true;
      const form = this.$refs[formName];

      this.roles.forEach((role) => {
        if(role.id == form.$options.propsData.model.role_id){
          form.$options.propsData.model.role_name = role.name
        }
      });

      form.validate(async (valid) => {
        if (valid) {
          let parts = this.form.full_name.split(" "),
            first = parts.shift(),
            last = parts.shift() || ""

          if (this.form.site_id === 0)
          {
            this.form.site_id = null
          }
          const user_role_sites = [];
          user_role_sites.push({
            role_id: this.form.role_id,
            site_id: this.form.site_id
            }
          );
          let payload;
          payload = {
            id: this.customer.id,
            first_name: first,
            last_name: last,
            email: this.form.email,
            phone_number: this.form.phone_number,
            user_role_sites: user_role_sites
          };

          if(this.form.password != null || this.form.password != "")
            payload = {
              id: this.customer.id,
              first_name: first,
              last_name: last,
              email: this.form.email,
              phone_number: this.form.phone_number,
              password: this.form.password,
              user_role_sites: user_role_sites
            };
          // let params = { ...this.form };

          this.editUser(payload)
              .then(() => {
                this.hideEditUserDialog();
                this.clearForm();
                this.setShouldFetchUsers(true);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>